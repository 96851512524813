import BackendsManagementRestClient from "api/rest/BackendManagementRestClient";
import {
    addBackendManagement, addBackendManagementUser, deleteBackendManagement, deleteBackendManagementUser,
    setBackendManagementList,
    setBackendManagementLoading, setBackendManagementSecretKey, updateBackendManagement
} from "reducers/infrastructure/backendManagementSlice";
import {
    setPEIBackendManagementList,
} from "reducers/infrastructure/PEIbackendManagementSlice";
import BackendDTO from "types/model/infrastructure/BackendDTO";
import {toastr} from "react-redux-toastr";
import GrantBackendAccessResponse from "../../types/payload/infrastructure/backend/GrantBackendAccessResponse";
import RevokeBackendAccessResponse from "../../types/payload/infrastructure/backend/RevokeBackendAccessResponse";
import GetBackendSecretKeyResponse from "../../types/payload/infrastructure/backend/GetBackendSecretKeyResponse";
import CreateDownloadBackendLinkResponse
    from "../../types/payload/infrastructure/backend/CreateDownloadBackendLinkResponse";
import CreateDownloadBackendPropertiesLinkResponse
    from "../../types/payload/infrastructure/backend/CreateDownloadBackendPropertiesLinkResponse";
import { showErrorNotification } from "components/toastr/notificationActions";
import PEIDashletsRestClient from "api/rest/PEIDashletsRestClient";

export const listBackends = () => (dispatch: any) => {
    dispatch(setBackendManagementLoading(true))
    BackendsManagementRestClient.list()
        .then((backends: BackendDTO[]) => dispatch(setBackendManagementList(backends)))
        .catch(error => showErrorNotification("List Backends", getErrorMessage(error)))
        .finally(() => dispatch(setBackendManagementLoading(false)));
    
    PEIDashletsRestClient.listUserBackends()
        .then(backends => dispatch(setPEIBackendManagementList(backends)))
        .catch(error => console.error(error))
};

export const createBackend = (backend: BackendDTO) => (dispatch: any) => {
    dispatch(setBackendManagementLoading(true));
    BackendsManagementRestClient.create(backend)
        .then((backend: BackendDTO) => dispatch(addBackendManagement(backend)))
        .catch(error => showErrorNotification("Create Backend", getErrorMessage(error)))
        .finally(() => dispatch(setBackendManagementLoading(false)));
}

export const updateBackend = (backend: BackendDTO) => (dispatch: any) => {
    dispatch(setBackendManagementLoading(true));
    BackendsManagementRestClient.update(backend.id, backend)
        .then((backend: BackendDTO) => dispatch(updateBackendManagement(backend)))
        .catch(error => showErrorNotification("Update Backend", getErrorMessage(error)))
        .finally(() => dispatch(setBackendManagementLoading(false)));
}

export const deleteBackend = (backendId: number) => (dispatch: any) => {
    dispatch(setBackendManagementLoading(true));
    BackendsManagementRestClient.delete(backendId)
        .then((backendId: number) => dispatch(deleteBackendManagement(backendId)))
        .catch(error => showErrorNotification("Delete Backend", getErrorMessage(error)))
        .finally(() => dispatch(setBackendManagementLoading(false)));
}

export const grantAccess = (backendId: number, username: string) => (dispatch: any) => {
    dispatch(setBackendManagementLoading(true));
    BackendsManagementRestClient.grantAccess(backendId, username)
        .then((response: GrantBackendAccessResponse) => dispatch(addBackendManagementUser(response.backendUser)))
        .catch(error => showErrorNotification("Grant Access", getErrorMessage(error)))
        .finally(() => dispatch(setBackendManagementLoading(false)));
}

export const revokeAccess = (backendId: number, username: string) => (dispatch: any) => {
    dispatch(setBackendManagementLoading(true));
    BackendsManagementRestClient.revokeAccess(backendId, username)
        .then((response: RevokeBackendAccessResponse) =>
            dispatch(deleteBackendManagementUser(response.backendId, response.username)))
        .catch(error => showErrorNotification("Grant Access", getErrorMessage(error)))
        .finally(() => dispatch(setBackendManagementLoading(false)));
}

export const downloadBackend = (backendId: number) => (dispatch: any) => {
    dispatch(setBackendManagementLoading(true));
    console.log("Calling downloadBackend" );
    BackendsManagementRestClient.getDownloadLink(backendId)
        .then((response: CreateDownloadBackendLinkResponse) => {
            window.open(BackendsManagementRestClient.createBackendDownloadLink(response.token), "_blank");
        })
        .catch(error => showErrorNotification("Download Backend: " + backendId, getErrorMessage(error)))
        .finally(() => dispatch(setBackendManagementLoading(false)));
}

export const downloadDefaultBackend = () => (dispatch: any) => {
    dispatch(setBackendManagementLoading(true));
    console.log("Calling downloadDefaultBackend" );
    BackendsManagementRestClient.getDefaultDownloadLink()
        .then(handleDownloadBackend)
        .catch(error => showErrorNotification("Download Default Backend", getErrorMessage(error)))
        .finally(() => dispatch(setBackendManagementLoading(false)));
}

export const downloadBackendProperties = (backendId: number) => (dispatch: any) => {
    dispatch(setBackendManagementLoading(true));
    console.log("Calling downloadBackendProperties" );
    BackendsManagementRestClient.getDownloadPropertiesLink(backendId)
        .then((response: CreateDownloadBackendPropertiesLinkResponse) => {
            window.open(BackendsManagementRestClient.createBackendDownloadPropertiesLink(response.token), "_blank");
        })
        .catch(error => showErrorNotification("Download Backend Properties: " + backendId, getErrorMessage(error)))
        .finally(() => dispatch(setBackendManagementLoading(false)));
}


export const downloadDefaultBackendProperties = () => (dispatch: any) => {
    dispatch(setBackendManagementLoading(true));
    console.log("Calling downloadDefaultBackendProperties" );
    BackendsManagementRestClient.getDefaultDownloadPropertiesLink()
        .then(handleDownloadBackendProperties)
        .catch(error => showErrorNotification("Download Default Backend Properties", getErrorMessage(error)))
        .finally(() => dispatch(setBackendManagementLoading(false)));
}


const handleDownloadBackend = (response: CreateDownloadBackendLinkResponse) =>
    window.open(BackendsManagementRestClient.createBackendDownloadLink(response.token), "_blank");

const handleDownloadBackendProperties = (response: CreateDownloadBackendLinkResponse) =>
    window.open(BackendsManagementRestClient.createBackendDownloadPropertiesLink(response.token), "_blank");


export const getBackendSecretKey = (backendId: number) => (dispatch: any) => {
    dispatch(setBackendManagementLoading(true));
    BackendsManagementRestClient.getBackendSecretKey(backendId)
        .then((response: GetBackendSecretKeyResponse) =>
            dispatch(setBackendManagementSecretKey(response.backendId, response.backendSecretKey)))
        .catch(error => showErrorNotification("Backend Secret Key", getErrorMessage(error)))
        .finally(() => dispatch(setBackendManagementLoading(false)));
}

const getErrorMessage = (restError: any) => JSON.parse(restError.message)?.message || "Server Error";
