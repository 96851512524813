import DemoTargetsGenerationParams from "types/model/infrastructure/simulation/DemoTargetsGenerationParams";
import SimulationOperationStompResponse
    from "types/payload/infrastructure/simulation/SimulationOperationStompResponse";
import * as simulationWebSocketClient from "api/websocket/hlc/simulationWebSocketClient"
import {showErrorNotification, showSuccessNotification} from "components/toastr/notificationActions";

export const generateDemoTargets = (backendKey: string, params: DemoTargetsGenerationParams) => (dispatch: any, getState: any) =>
    simulationWebSocketClient.generateDemoTargets(getState(), backendKey, {params});

export const deleteDemoTargets = (backendKey: string, targetNamePrefix: string | null = null) => (dispatch: any, getState: any) =>
    simulationWebSocketClient.deleteDemoTargets(getState(), backendKey, targetNamePrefix);

export const handleSimulationOperationResponse = (response: SimulationOperationStompResponse) => (dispatch: any, getState: any) => {
    const { hasErrors, errorMessage, message} = response;

    !hasErrors
        ? showSuccessNotification("Simulation", message)
        : showErrorNotification("Simulation", errorMessage);
};
