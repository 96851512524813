import * as integrationWebSocketClient from "api/websocket/hlc/integrationWebSocketClient";
import ListIntegrationsStompResponse from "types/payload/settings/integrations/ListIntegrationsStompResponse";
import {setIntegration, setIntegrations, setIntegrationsLoading} from "reducers/settingsSlice";
import Integration from "types/model/settings/integration/Integration";
import SaveIntegrationStompResponse from "../../types/payload/settings/integrations/SaveIntegrationStompResponse";
import { toastr } from "react-redux-toastr";
import TestIntegrationConfigStompResponse from "types/payload/settings/integrations/TestIntegrationConfigStompResponse";
import { showErrorNotification, showInfoNotification } from "components/toastr/notificationActions";
// import { logoutUserAsNotRegisteredInBackend } from "actions/authActions";

export const listIntegrations = (backendKey: string) => (dispatch: any, getState: any) => {
    dispatch(setIntegrationsLoading(true));
    integrationWebSocketClient.listIntegrations(getState(), backendKey);
}

export const listAiModels = (backendKey: string, aiType: string, apiKey: string, anthropicVersion: string) => (dispatch: any, getState: any) => {
    integrationWebSocketClient.listAiModels(getState(), backendKey, {
        aiType,
        apiKey,
        anthropicVersion,
    });
}

export const handleListIntegrations = (response: ListIntegrationsStompResponse) => (dispatch: any) => {
    const {hasErrors, errorMessage, integrations} = response;
    if (!hasErrors) {
        dispatch(setIntegrations(integrations));
    } else {
        // todo: handle error
        console.error(errorMessage);
        // dispatch(setIntegrations([]));
        // if (response.errorMessage.includes("User not registered")) {
        //     toastr.error("User not found", errorMessage);
        //     dispatch(logoutUserAsNotRegisteredInBackend());
        // }
    }
    dispatch(setIntegrationsLoading(false));
};

export const saveIntegration = (backendKey: string, integration: Integration) =>
    (dispatch: any, getState: any) => {
        dispatch(setIntegrationsLoading(true));
        const userId = getState().userInfo.currentUserInfo.id;
        integrationWebSocketClient.saveIntegration(getState(), backendKey, {
            integration,
            userId
        });
    };

export const handleSaveIntegrationSettings = (response: SaveIntegrationStompResponse) =>
    (dispatch: any, getState: any) => {
        const {hasErrors, errorMessage, integration} = response;
        if (!hasErrors) {
            dispatch(setIntegration(integration))
            showInfoNotification("Integration Saved", integration.title + " Integration Saved Successfully.");
        } else {
            // todo: handle error
            console.error(errorMessage);
            showInfoNotification("Integration Save Failed", errorMessage);
        }
        dispatch(setIntegrationsLoading(false));
    };

export const handleTestIntegrationResponse = (response: TestIntegrationConfigStompResponse) =>
        (dispatch: any, getState: any) => {
            const {hasErrors, errorMessage, result} = response;
            if (!hasErrors) {
                showInfoNotification("Integration Test", result);
            } else {
                console.error(errorMessage);
                showErrorNotification("Integration Test Failed", errorMessage);
            }
        };

export const testIntegration = (backendKey: string, integration: Integration) =>
    (dispatch: any, getState: any) => {
        integrationWebSocketClient.testIntegration(getState(), backendKey, {integration});
    };