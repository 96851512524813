import React, {useState} from "react";
import GenAIIntegrationConfigProps from "./GenAIIntegrationConfigProps.types";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import CustomTextField from "components/common/TextField/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import axios from "axios";

const GenericGenAIIntegrationConfig: React.FC<GenAIIntegrationConfigProps> = ({
                                                                                  integration,
                                                                                  handleChangeProperty,
                                                                                  errors
                                                                              }) => {
    const {
        enabled,
        name,
        apiUrl,
        apiKey,
        model,
        role
    } = integration;

    // empty config
    const [models, setModels] = useState<string[]>([]);

      const fetchModels = async () => {
        try {
            const response = await axios.get('https://api.llama.ai/v1/models', {
                headers: {
                    // 'Authorization': `Bearer ${apiKey}`,
                    'X-API-Key': apiKey,
                    'Accept': 'application/json',
                },
            });
          setModels(response.data.models);
        } catch (error) {
          console.error('Error fetching models:', error);
        }
    }

    return (
        <>
            <Grid item container spacing={2} xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={2} style={{textAlign: "left"}}>
                    <InputLabel htmlFor="name">Name</InputLabel>
                </Grid>
                <Grid item xs={5}>
                    <CustomTextField
                        disabled={!enabled}
                        name="name"
                        fullWidth
                        textAlign="left"
                        value={name}
                        onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                    />
                    {enabled && errors.name &&
                        <div style={{color: 'red', marginTop: '4px', textAlign: 'left'}}>{errors.name}</div>}
                </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={2} style={{textAlign: "left"}}>
                    <InputLabel htmlFor="apiUrl">API URL</InputLabel>
                </Grid>
                <Grid item xs={5}>
                    <CustomTextField
                        disabled={!enabled}
                        name="apiUrl"
                        fullWidth
                        textAlign="left"
                        value={apiUrl}
                        onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                    />
                    {enabled && errors.openAIApiKey &&
                        <div style={{color: 'red', marginTop: '4px', textAlign: 'left'}}>{errors.openAIApiKey}</div>}
                </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={2} style={{textAlign: "left"}}>
                    <InputLabel htmlFor="apiKey">API Key</InputLabel>
                </Grid>
                <Grid item xs={5}>
                    <CustomTextField
                        disabled={!enabled}
                        name="apiKey"
                        fullWidth
                        textAlign="left"
                        value={apiKey}
                        onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        onBlur={() => {
                            if (apiKey) {
                                fetchModels();
                            }
                        }}
                    />
                    {enabled && errors.openAIApiKey &&
                        <div style={{color: 'red', marginTop: '4px', textAlign: 'left'}}>{errors.openAIApiKey}</div>}
                </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={2} style={{textAlign: "left"}}>
                    <InputLabel htmlFor="model">Model Name</InputLabel>
                </Grid>
                <Grid item xs={5}>
                    <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={models}
                        onChange={(event: any, newValue : any) => {
                            handleChangeProperty("model", newValue);
                        }}
                        onInputChange={(event: any, newInputValue) => {
                            handleChangeProperty("model", newInputValue);
                        }}
                        disabled={!enabled}
                        value={model || ""}
                        renderInput={(params) => <TextField {...params} 
                        />}
                    />
                    {/* <TextField
                        disabled={!enabled}
                        name="model"
                        fullWidth
                        textAlign="left"
                        value={model}
                        onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                    /> */}
                    {enabled && errors.openAIModel &&
                        <div style={{color: 'red', marginTop: '4px', textAlign: 'left'}}>{errors.openAIModel}</div>}
                </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={2} style={{textAlign: "left"}}>
                    <InputLabel htmlFor="role">Role Name</InputLabel>
                </Grid>
                <Grid item xs={5}>
                    <CustomTextField
                        disabled={!enabled}
                        name="role"
                        fullWidth
                        textAlign="left"
                        value={role}
                        onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                    />
                    {enabled && errors.openAIModel &&
                        <div style={{color: 'red', marginTop: '4px', textAlign: 'left'}}>{errors.openAIModel}</div>}
                </Grid>
            </Grid>
        </>
    );
};

export default GenericGenAIIntegrationConfig;

