import React, {useEffect, useState} from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as _ from 'lodash';
import HLCDashletsRestClient from 'api/rest/HLCDashletsRestClient';
import {useDispatch, useSelector} from 'react-redux';
import {loadHLCChartTemplates} from '../actions';
import {selectStompClient, selectIsStompClientConnected} from 'reducers/webSocketSlice';
import * as webSocketClient from "../webSocketClient";
import {TEMPLATES_DASHLET_CONFIG_OPTIONS_PROPERTY} from "../config";
import {selectTemplateNames, selectTemplates, setDashletDataConfigProperty, setDashletOptionsProperty} from "reducers/dashboard/createDashletSlice";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import { selectIsLoadingTemplateNames, toggleIsLoadingTemplateNames } from 'reducers/dashboard/dashboardSlice';
import { ChevronDown12Regular } from '@fluentui/react-icons';
import { ListSubheader } from '@material-ui/core';

const HLCChartDashletCreate = (props) => {
    const {dashletErrors, dashletData} = props;
    const {backendKey, templateName} = dashletData.config;
    const [backends, setBackends] = useState([]);
    const [isLoadingBackends, setIsLoadingBackends] = useState(false);
    // const [templateNames, setTemplateNames] = useState([]);
    // const [isLoadingTemplateNames, setIsLoadingTemplateNames] = useState(false);
    const isLoadingTemplateNames = useSelector(selectIsLoadingTemplateNames);
    const templates = useSelector(selectTemplates)
    
    const stompClient = useSelector(selectStompClient);
    const isStompClientConnected = useSelector(selectIsStompClientConnected);
    const dispatch = useDispatch();

    const onChangeDashletConfigPropertyHandler = (event) => {
        event.preventDefault();
        dispatch(setDashletDataConfigProperty(event.target.name, event.target.value));
    };

    useEffect(() => {
        setIsLoadingBackends(true);
        HLCDashletsRestClient.listUserBackends()
            .then(backends => {
                setBackends(backends);
                setIsLoadingBackends(false);
            })
            .catch(error => {
                console.error(error);
                setIsLoadingBackends(false);
            });
    }, []);

    useEffect(() => {
        if (backendKey) {
            dispatch(toggleIsLoadingTemplateNames(true));
            // setIsLoadingTemplateNames(true);
            dispatch(loadHLCChartTemplates(backendKey));
        }
    }, [backendKey]);

    const systemTemplates = templates
    ?.filter((option) => option.defaultDashboardTemplate)
    .map((option) => option.name) || [];

    const chartTemplates = templates
    ?.filter((option) => !option.defaultDashboardTemplate)
    .map((option) => option.name) || [];

    return <React.Fragment>
           <FormControl key="dashlet-hlc-chart-backendKey-1582673886219"
                     fullWidth
                     error={dashletErrors.backendKey}
                     margin="dense"
           >
            <InputLabel id="dashlet-hlc-chart-backendKey-input-label-1582673886219">
                HLC Backend
            </InputLabel>
            <Select
                labelId="dashlet-hlc-chart-backendKey-label-1582673886219"
                id="backendKey"
                name="backendKey"
                value={backendKey ? backendKey : ''}
                onChange={onChangeDashletConfigPropertyHandler}
                disabled={isLoadingBackends}
                IconComponent={(props) => (
                    <ChevronDown12Regular {...props} style={{ height: '20px', width: '20px', top: '8px' }} />
                )}
            >
                <MenuItem key="dashlet-hlc-chart-backendKey-menu-item-1582673956819" value=""></MenuItem>
                {_.map(backends, (backend) => {
                    return (
                        <MenuItem
                            key={`dashlet-hlc-chart-backendKey-menu-item-key-${backend.backendKey}`}
                            value={backend.backendKey}>
                            {backend.name}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>   
           <FormControl key="dashlet-hlc-chart-templateName-1582673886219"
                     fullWidth
                     error={dashletErrors.templateName}
                     margin="dense"
           >
            <InputLabel id="dashlet-hlc-chart-templateName-input-label-1582673886219">
                HLC Chart Template
            </InputLabel>
            <Select
                labelId="dashlet-hlc-chart-templateName-label-1582673886219"
                id="templateName"
                name="templateName"
                value={templateName ? templateName : ''}
                onChange={onChangeDashletConfigPropertyHandler}
                disabled={isLoadingTemplateNames || !backendKey}
                IconComponent={(props) => (
                    <ChevronDown12Regular {...props} style={{ height: '20px', width: '20px', top: '8px' }} />
                )}
            >
                <MenuItem key="dashlet-hlc-chart-templateName-menu-item-1582673956819" value=""></MenuItem>
                {chartTemplates && <ListSubheader>Chart Template</ListSubheader>}
                {_.map(chartTemplates, (templateName) => {
                    return (
                        <MenuItem
                            key={`dashlet-hlc-chart-templateName-menu-item-key-${templateName}`}
                            value={templateName}>
                            {templateName}
                        </MenuItem>
                    )
                })}
                {systemTemplates && <ListSubheader>System Template</ListSubheader>}
                {_.map(systemTemplates, (templateName) => {
                    return (
                        <MenuItem
                            key={`dashlet-hlc-chart-templateName-menu-item-key-${templateName}`}
                            value={templateName}>
                            {templateName}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>

        {/* <HLCUserSessionWebSocketSubscription
            key={`dashlet-hlc-chart-templates-subscription-1582673956819`}
            subscriptionDestination={webSocketClient.LOAD_HLC_CHART_TEMPLATES_SUBSCRIPTION}
            handleMessage={response => {
                const {templates} = response;
                dispatch(setDashletOptionsProperty(TEMPLATES_DASHLET_CONFIG_OPTIONS_PROPERTY, templates));
                // setTemplateNames(templates.map(t => t.name));
                dispatch(toggleIsLoadingTemplateNames(false));
                // setIsLoadingTemplateNames(false);
            }}
        /> */}
    </React.Fragment>;
};

export default HLCChartDashletCreate;