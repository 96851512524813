import * as React from 'react';
import * as _ from 'lodash'
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import { Toolbar, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import { useDispatch } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { closeDashletPreview, setIsChartDataLoading, setTimeRanges } from 'reducers/dashboard/dashboardSlice';
import { getViewDashletComponent } from 'dashlets/dashletsConfig';
import NotSupportedDashlet from 'components/dashboard/dashlets/NotSupportedDashlet/NotSupportedDashlet';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import LaunchIcon from '@material-ui/icons/Launch';
import Highcharts from 'highcharts';
import { generateReport, openSpikeInAnalyzer } from 'dashlets/HLCChartDashlet/actions';
import { DASHLET_TYPE_NAME } from 'dashlets/AnomalyDetectionDashlet/constants';
import { ArrowForward24Filled, DatabaseSearch24Filled, Dismiss24Filled, ZoomOut24Filled } from '@fluentui/react-icons';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
    appBar: {
        width: '100%',
        maxWidth: 'none',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 1px 7px rgba(0, 0, 0, 0.25)',
        height: '48px',
        minHeight: '48px',
    },
    button: {
        top: '-8px',
        color:"#242424"
    }
});

const DashletPreview = ({ classes, dashlet }) => {
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(closeDashletPreview());
        if (dashlet.type != DASHLET_TYPE_NAME) {
            dispatch(setIsChartDataLoading(true))
        }
    }

    const handleResetZoom = () => {
        _.each(Highcharts.charts, (chart) => {
            if (chart) {
                chart.zoomOut();
                chart.xAxis[0].setExtremes(null, null);
                dispatch(setTimeRanges({startTime: chart.xAxis[0].dataMin, endTime: chart.xAxis[0].dataMax}))
            }
        });
    }

    const handleGenerateReport = () => {
        dispatch(generateReport(dashlet))
    }
        
    const openChart = () => {
        dispatch(openSpikeInAnalyzer(dashlet))
    }

    const ViewDashletComponent = getViewDashletComponent(dashlet.type);
    return (
        <div>
            <Dialog
                fullScreen
                open={true}
                onClose={handleClose}
            >
                <AppBar sx={{ position: 'relative' }} className={classes.appBar}>
                    <Toolbar>

                        <div className={classes.grow} />
                        {dashlet.type == 'ANOMALY_DETECTION_DASHLET' ? <Tooltip title="Generate Report">
                            <IconButton
                                edge="start"
                                // color="inherit"
                                onClick={handleGenerateReport}
                                aria-label="close"
                                className={classes.button}
                            >
                                <DatabaseSearch24Filled />
                            </IconButton>
                        </Tooltip> : null}
                        <Tooltip title="Reset Zoom">
                            <IconButton
                                edge="start"
                                // color="primary"
                                onClick={handleResetZoom}
                                aria-label="close"
                                className={classes.button}
                            >
                                <ZoomOut24Filled />
                            </IconButton>
                        </Tooltip>
                        {dashlet.type != "HEATMAP_DASHLET_TYPE" && dashlet.type != "PEI_CHART_BAG_CHART_DASHLET_TYPE" && dashlet.type != "HLC_CHART_DASHLET_TYPE"
                         && dashlet.type != "HEATMAP_ALERT_HISTORY_TYPE" ? <Tooltip title="Load Template With Selected Time Range">
                            <IconButton
                                edge="start"
                                // color="primary"
                                onClick={openChart}
                                aria-label="close"
                                className={classes.button}                                
                            >
                                <ArrowForward24Filled />
                            </IconButton>
                        </Tooltip> : null}
                        <IconButton
                            edge="start"
                            // color="primary"
                            onClick={handleClose}
                            aria-label="close"
                            className={classes.button}
                        >
                            <Dismiss24Filled />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <div id="dashlet_preview_container" style={{ height: '100%', width: '100%', marginTop: '60px' }}>
                    {ViewDashletComponent ? (
                        <ViewDashletComponent dashlet={dashlet} isResizing={false} isPreviewOpen={true} />
                    ) : <NotSupportedDashlet dashlet={dashlet} />}
                </div>
            </Dialog>

        </div>
    );
}

export default withStyles(styles)(DashletPreview);