import {whiteColor, blackColor} from "assets/jss/global.jsx";

const dialogTitleStyle = theme => ({
  root: {
    margin: 0,
    // padding: theme.spacing(2),
    padding: "18px 18px 0px",
    display: 'flex',

    // backgroundColor: '#fafafa',
    // borderBottom: '1px solid #eaeded',
  },
  typography: {
    color: '#242424',
    fontSize: '20px',
    fontWeight: 600,
    width: '100%'
  },
  closeButton: {
    // position: 'absolute',
    // // right: theme.spacing(1),
    // right: "2px",
    // top: theme.spacing(1),
    // // color: blackColor,
    // color:"#424242"
  },
  minimizeButton: {
    top: '8px',
    color: blackColor,
    right: '108px',
    position: 'absolute',
  },
  maximizeButton: {
    // top: '8px',
    // color: blackColor,
    // right: '60px',
    // position: 'absolute',
  },
  resetZoomButton: {
    // top: '11px',
    // color: '#000',
    // position: 'absolute',
  },
  openChart: {
    // top: '7px',
    // color: '#000',
    // right: '108px',
    // position: 'absolute',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'end',
  },
  button: {
    color: '#242424',
  }
});

export default dialogTitleStyle;