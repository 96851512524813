import React, {useEffect} from "react";
import HLCBackend from "types/HLCBackend";
import {Box} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "../Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import BackendsRestClient from "api/rest/BackendsRestClient";
import {toastr} from "react-redux-toastr";
import {getErrorMessage} from "utils/errorUtils";
import { ChevronDown12Regular } from "@fluentui/react-icons";
import { showErrorNotification } from "components/toastr/notificationActions";
import PEIDashletsRestClient from "api/rest/PEIDashletsRestClient";

interface BackendSelectionRowProps {
    backendKey: string | null;
    onChangeBackend: (backendKey: string) => void;
    selectedTabLogs?: number;
}

const BackendSelectionRow: React.FC<BackendSelectionRowProps> = ({
                                                                     backendKey,
                                                                     onChangeBackend,
                                                                     selectedTabLogs,
                                                                 }) => {
    const [backends, setBackends] = React.useState<HLCBackend[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);

    const [PEIbackends, setPEIBackends] = React.useState<HLCBackend[]>([]);
    const [loadingPEI, setLoadingPEI] = React.useState<boolean>(false);

    // initial loading of the Backend list
    useEffect(() => {
        setLoading(true)
        BackendsRestClient.listUserBackends()
            .then(backends => setBackends(backends))
            .catch(error => showErrorNotification("Backends", getErrorMessage(error)))
            .finally(() => setLoading(false));

        setLoadingPEI(true)
        PEIDashletsRestClient.listUserBackends()
            .then(backends => setPEIBackends(backends))
            .catch(error => console.error(error))
            .finally(() => setLoadingPEI(false));
    }, []);

    return (
        <Box
            style={{
                display: 'flex',
                width: "100%",
                paddingLeft: "32px",
            }}
        >
            <Grid
                container
                spacing={2}
                direction="column"
                style={{
                    marginTop: "8px",
                    marginBottom: "8px",

                }}
            >
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    alignItems="center">
                    <Grid item xs={3} md={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="enable">Backend</InputLabel>
                    </Grid>
                    <Grid item xs={3} style={{paddingRight: "12px"}}>
                        <Select value={backendKey} name="intergationsBackendKey"
                                onChange={(event: any) =>
                                    onChangeBackend(event?.target?.value)}
                                IconComponent={(props : any) => (
                                    <ChevronDown12Regular {...props} style={{ height: '20px', width: '20px', top: '8px' }} />
                                )}
                        >
                            {selectedTabLogs === 1 ? PEIbackends.map(backend =>
                                <MenuItem
                                    data-testid={`integration-backend-dropdown-option-${backend.backendKey}`}
                                    key={`integration-backend-menu-item-${backend.backendKey}`}
                                    value={backend.backendKey}
                                >
                                    {backend.name}
                                </MenuItem>
                            ): backends.map(backend =>
                                <MenuItem
                                    data-testid={`integration-backend-dropdown-option-${backend.backendKey}`}
                                    key={`integration-backend-menu-item-${backend.backendKey}`}
                                    value={backend.backendKey}
                                >
                                    {backend.name}
                                </MenuItem>
                            )}
                        </Select>
                    </Grid>
                </Grid>

            </Grid>
        </Box>
    );
};

export default BackendSelectionRow;