import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import { GET_EMAIL_CONFIG_URL_PEI_SUBSCRIPTION, SAVE_EMAIL_CONFIGURATION_FOR_PEI_SUBSCRIPTION, TEST_EMAIL_CONFIG_URL_FOR_PEI_SUBSCRIPTION } from "views/gate/AddEmailConfiguration/webSocketClient";
import {selectIsStompClientConnected, selectStompClient} from "reducers/webSocketSlice";
import { getEmailConfigurationResult, handleGetEmailConfigurationResult, saveEmailConfigurationResult, testEmailConfigurationResultPEI } from "actions/EmailConfigurationActions";
import WebSocketSubscription from "websocket/WebSocketSubscription";
import { LOGS_PEI_SUBSCRIPTION } from 'api/websocket/pei/PEIbackendsLogsWebSocketClient';
import { handleLoadLogsPEI } from 'actions/infrastructure/backendsLogsActions';
import { handleImportDashlet } from 'reducers/dashboard/createDashletSlice';
import { IMPORT_DASHLET_SUBSRIPTION } from '../webSocketClient';

const PEIChartBagWebSocketSubscription = (props) => {
    const wsClient = useSelector(selectStompClient);
    const isWebSocketConnected = useSelector(selectIsStompClientConnected);
    const dispatch = useDispatch();

    return <React.Fragment>
        {isWebSocketConnected &&
        [<WebSocketSubscription
            key="pei-ws-subscr-email-config-1582840873831"
            stompClient={wsClient}
            subscriptionDestination={GET_EMAIL_CONFIG_URL_PEI_SUBSCRIPTION}
            handleMessage={message => dispatch(handleGetEmailConfigurationResult(message))}
        />,

        <WebSocketSubscription
            key="pei-ws-subscr-save-email-config-1582840873831"
            stompClient={wsClient}
            subscriptionDestination={SAVE_EMAIL_CONFIGURATION_FOR_PEI_SUBSCRIPTION}
            handleMessage={message => dispatch(saveEmailConfigurationResult(message))}
        />,

        <WebSocketSubscription
            key="pei-get-email-cinfiguration-1582840873831"
            stompClient={wsClient}
            subscriptionDestination={TEST_EMAIL_CONFIG_URL_FOR_PEI_SUBSCRIPTION}
            handleMessage={message => dispatch(testEmailConfigurationResultPEI(message))}
        />,
        <WebSocketSubscription
            key="pei-ws-subscr-logs-1582840873831"
            stompClient={wsClient}
            subscriptionDestination={LOGS_PEI_SUBSCRIPTION}
            handleMessage={message => dispatch(handleLoadLogsPEI(message))}
        />,
        <WebSocketSubscription
            key="pei-ws-subscr-import-1582840873831"
            stompClient={wsClient}
            subscriptionDestination={IMPORT_DASHLET_SUBSRIPTION}
            handleMessage={message => {
            dispatch(handleImportDashlet(message))}}
        />]
    }
    </React.Fragment>
}

export default PEIChartBagWebSocketSubscription;