import {getViewDashletComponent} from "dashlets/dashletsConfig";
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DashletWrapper from "./DashletWrapper";
import {addChartOnDashboard} from "actions/dashboardActions";
import {removeDashlet, refreshDashletData, updateDashletConfig as updateDashletConfiguration, exportDashletData} from "actions/dashboard/dashletActions";
import { dashletPreview } from "actions/dashboardActions";
import EditDashletConfig from "../../../views/dashboard/EditDashletConfig/EditDashletConfig";
import NotSupportedDashlet from "./NotSupportedDashlet/NotSupportedDashlet";
import { selectIsDashletPreviewOpen, selectSelectedDashletId } from "reducers/dashboard/dashboardSlice";
import DashletPreview from "dashlets/components/dashletPreview/DashletPreview";

interface DashletContainerProps {
    dashboardType: any;
    dashlet: any;
    isResizing: boolean;
}

const DashletContainer: React.FC<DashletContainerProps> = ({
                                                         dashboardType,
                                                         dashlet,
                                                         isResizing
                                                     }) => {
    const dispatch = useDispatch();
    const [isEditDashletConfigOpened, setIsEditDashletConfigOpened] = useState<boolean>(false);
    const dashletPreviewOpen = useSelector(selectIsDashletPreviewOpen);
    const selectedDashletId = useSelector(state => selectSelectedDashletId(state))
    const renderDashletByType = () => {
        const ViewDashletComponent = getViewDashletComponent(dashlet.type);

        return ViewDashletComponent ? (
            <ViewDashletComponent dashlet={dashlet} isResizing={isResizing} isPreviewOpen={false}/>
        ) : <NotSupportedDashlet dashlet={dashlet}/>;
    };

    const updateDashletConfig = (pinned:boolean) => {
        dashlet = {
            ...dashlet,
            config: {
                ...dashlet.config,
                autoDelete: pinned
            }
        }
        dispatch(updateDashletConfiguration(dashlet.id, dashlet.config, false));
    }

    if (!dashlet) {
        return null;
    }

    return <React.Fragment>
        {/*@ts-ignore*/}
        <DashletWrapper
            dashboardType={dashboardType}
            dashlet={dashlet}
            onRefreshHandler={() => dispatch(refreshDashletData(dashlet))}
            onRemoveHandler={() => dispatch(removeDashlet(dashlet.id))}
            onClickMenuHandler={() => dispatch(addChartOnDashboard())}
            onEditConfigHandler={() => setIsEditDashletConfigOpened(true)}
            updateDashletConfig={(pinned:boolean) => updateDashletConfig(pinned)}
            onDashletPreviewHandler ={() => dispatch(dashletPreview(dashlet.id))}
            onExportDashletHandler={() => dispatch(exportDashletData(dashlet))}
        >
            {dashletPreviewOpen ? dashlet.id === selectedDashletId ? renderDashletByType() : null : renderDashletByType()}
            {dashletPreviewOpen && dashlet.id === selectedDashletId && <DashletPreview dashlet={dashlet}/>}
        </DashletWrapper>

        {isEditDashletConfigOpened && <EditDashletConfig
            dashlet={dashlet}
            open={isEditDashletConfigOpened}
            onClose={() => setIsEditDashletConfigOpened(false)}
        />}
    </React.Fragment>;
}

export default DashletContainer;
