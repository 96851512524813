import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {filter as _filter} from "lodash";
import {
    activeCreateDashletStepSelector, createDashletDataSelector, createDashletErrorsSelector,
    createDashletStepsSelector,
    isCreateDashletLockedSelector,
    isCreateDashletOpenedSelector
} from "selectors/dashboard/CreateDashletSelectors";
import CreateDashlet from "./CreateDashlet";
import {
    changeActiveStep,
    closeCreateDashlet, setDashletDataConfigProperty,
    setDashletDataProperty,
    setIsCreateDashletLocked, submitCreateDashlet
} from "reducers/dashboard/createDashletSlice";
import {selectHasFinanceRole} from "reducers/userInfoSlice";
import { DASHLET_TYPE_NAME } from 'dashlets/AnomalyDetectionDashlet/constants';

class CreateDashletContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {}
        };
    }

    setActiveStep = (step) => {
        this.props.changeActiveStep(step);
    };

    handleNext = () => {
        this.setActiveStep(this.props.activeStep + 1);
    };

    handleBack = () => {
        this.setActiveStep(this.props.activeStep - 1);
    };

    lockForm = () => {
        this.props.setIsLocked(true);
    };

    unlockForm = () => {
        this.props.setIsLocked(false);
    };

    onClickSubmitHandler = (event) => {
        event.preventDefault();

        this.props.submitCreateDashlet();
    };

    onChangeInputHandler = (event) => {
        event.preventDefault();

        this.props.setDashletDataProperty(event.target.name, event.target.value);
    };

    render() {
        const {isOpen, isLocked, closeCreateDashlet, createDashletSteps,
            activeStep, dashletData, dashletErrors, dashletTypesDropdownOptions, dashletConfigOptions, setDashletDataConfigProperty, hasFinanceRole} = this.props;
        return isOpen ? (
            <CreateDashlet
                createDashletSteps={createDashletSteps}
                isLocked={isLocked}
                dashletData={dashletData}
                dashletConfigOptions={dashletConfigOptions}
                dashletErrors={dashletErrors}
                activeStep={activeStep}
                dashletTypes={_filter(dashletTypesDropdownOptions, (opt) => {
                    if (opt.value === DASHLET_TYPE_NAME) {
                        return false;
                    }
                    return true;
                    //todo: AP 12/02/2025 - Temporary disabled finance role check
                    // if (opt.value !== "FIN_OPS_DASHLET_TYPE") {
                    //     return true;
                    // } else {
                    //     return hasFinanceRole;
                    // }
                })}
                handleConfirm={this.onClickSubmitHandler}
                handleClose={closeCreateDashlet}
                handleBack={this.handleBack}
                handleNext={this.handleNext}
                handleChangeInput={this.onChangeInputHandler}
                handleChangeDashletConfigProperty={setDashletDataConfigProperty}
            />
        ) : null;
    }
}

CreateDashletContainer.propTypes = {
    //data
    isLocked: PropTypes.bool,
    createDashletSteps: PropTypes.array,
    activeStep: PropTypes.number,
    dashletData: PropTypes.object,
    dashletErrors: PropTypes.object,
    dashletTypesDropdownOptions: PropTypes.array,
    dashletConfigOptions: PropTypes.object,
    hasFinanceRole: PropTypes.bool,

    //func
    changeActiveStep: PropTypes.func,
    setIsLocked: PropTypes.func,
    setDashletDataProperty: PropTypes.func,
    setDashletDataConfigProperty: PropTypes.func,
    closeCreateDashlet: PropTypes.func,
    submitCreateDashlet: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        isOpen: isCreateDashletOpenedSelector(state),
        isLocked: isCreateDashletLockedSelector(state),
        createDashletSteps: createDashletStepsSelector(state),
        activeStep: activeCreateDashletStepSelector(state),
        dashletData: createDashletDataSelector(state),
        dashletErrors: createDashletErrorsSelector(state),
        dashletTypesDropdownOptions: state.createDashlet.dashletTypesDropdownOptions,
        dashletConfigOptions: state.createDashlet.dashletConfigOptions,
        hasFinanceRole: selectHasFinanceRole(state)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        closeCreateDashlet: () => {
            dispatch(closeCreateDashlet());
        },
        changeActiveStep: (activeStep) => {
            dispatch(changeActiveStep(activeStep));
        },
        setIsLocked: (isLocked) => {
            dispatch(setIsCreateDashletLocked(isLocked));
        },
        setDashletDataProperty: (propertyName, propertyValue) => {
            dispatch(setDashletDataProperty(propertyName, propertyValue));
        },
        setDashletDataConfigProperty: (propertyName, propertyValue) => {
            dispatch(setDashletDataConfigProperty(propertyName, propertyValue));
        },
        submitCreateDashlet: () => {
            dispatch(submitCreateDashlet());
        },
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateDashletContainer)