import DemoTargetsParams from "types/model/infrastructure/simulation/DemoTargetsParams";

const createDemoTargetParams = (defaults: Partial<DemoTargetsParams> = {}) => {
    return {
        databaseType: defaults.databaseType || "",
        targetsCount: defaults.targetsCount || 0,
    }
};

export default createDemoTargetParams;
