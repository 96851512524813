import * as backendsLogsWebSocketClient from "api/websocket/hlc/backendsLogsWebSocketClient";
import * as PEIbackendsLogsWebSocketClient from "api/websocket/pei/PEIbackendsLogsWebSocketClient";
import {setBackendsLogsLines, setBackendsLogsLoading} from "reducers/infrastructure/backendsLogsSlice";
import LogsStompResponse from "types/payload/infrastructure/logs/LogsStompResponse";

export const loadLogs = (backendKey: string, loadPEILogs: boolean) => (dispatch: any, getState: any) => {
    if (backendKey) {
        dispatch(setBackendsLogsLoading(true));
        if (!loadPEILogs) {
            backendsLogsWebSocketClient.loadLogs(getState(), backendKey, {
                linesCount: 500
            });
        } else {
            PEIbackendsLogsWebSocketClient.loadLogsPEI(getState(), backendKey, {
                linesCount: 500
            });
        }
    } else {
        dispatch(setBackendsLogsLoading(false));
        dispatch(setBackendsLogsLines([]));
    }
};

export const handleLoadLogs = (response: LogsStompResponse) => (dispatch: any) => {
    dispatch(setBackendsLogsLoading(false));
    dispatch(setBackendsLogsLines(response.logLines));
};

export const handleLoadLogsPEI = (response: any) => (dispatch: any) => {
    dispatch(setBackendsLogsLoading(false));
    dispatch(setBackendsLogsLines(response.logs));
};