import {BASE_API_URL} from "../../config";
import CommonCrudRestClient from "./CommonCrudRestClient";
import { ANOMALY_DASHBOARD } from "dashlets/AnomalyDetectionDashlet/constants";
import { showErrorNotification } from "components/toastr/notificationActions";


class DashboardRestClient extends CommonCrudRestClient {

    async create(dashboard) {
        let data = dashboard;
        if (dashboard.dashboardType === ANOMALY_DASHBOARD) {
            data = this.serializeJsonFields(dashboard);
        }
        return await super.post('', data).then(this.parseJsonFields);    
    }

    async load(id) {
        return await super.load(id).then(this.parseJsonFields);
    }

    async update(id, dashboard) {
        const data = {
            ...dashboard,
            layoutConfig: JSON.stringify(dashboard.layoutConfig),
            timeSettings: dashboard.timeSettings !== null ? JSON.stringify(dashboard.timeSettings) : null,
        };
        return await super.update(id, data).then(this.parseJsonFields);
    }

    async saveLayout(dashboardId, layoutConfig) {
        const data = {
            dashboardId: dashboardId,
            layoutConfig: JSON.stringify(layoutConfig)

        };
        return await this.post('/updateLayout', data)
            .then(resultObject => {
                resultObject.layoutConfig = JSON.parse(resultObject.layoutConfig);
                return resultObject;
            })
    }

    async getCustomerPortalUrl(subscriptionData) {
        if (!subscriptionData.customerId) {
            showErrorNotification("Get URL", "Unable get subscription plan.");
            return;
        }
        const data = {
            customerId: subscriptionData.customerId,
        }
        return await this.post('/getCustomerPortalUrl', data)
        .then(resultObject => {
            return resultObject;
        })
    }

    parseLayoutConfig(dashboard) {
        dashboard.layoutConfig = JSON.parse(dashboard.layoutConfig);
        return dashboard;
    }

    parseJsonFields(dashboard) {
        if (dashboard.dashboardType === ANOMALY_DASHBOARD) {
            dashboard.timeSettings = JSON.parse(dashboard.timeSettings);
        }
        dashboard.layoutConfig = JSON.parse(dashboard.layoutConfig);
        return dashboard;
    }

    serializeJsonFields(dashboard) {
        return {
            ...dashboard,
            timeSettings: JSON.stringify(dashboard.timeSettings),
        }
    }
}

export default new DashboardRestClient(`${BASE_API_URL}/dashboard/groups/dashboards`);