import {createSlice} from "@reduxjs/toolkit";
import * as _ from "lodash";
import {
    getBuildDashletObjectFunc,
    getDashletTypeLabel,
    getDashletTypes,
    getValidateConfigFunc
} from "../../dashlets/dashletsConfig";
import {createDashletDataSelector} from "../../selectors/dashboard/CreateDashletSelectors";
import {createDashlet} from "../../actions/dashboard/dashletActions";
import { toastr } from "react-redux-toastr";
import * as webSocketClient from "dashlets/HLCChartDashlet/webSocketClient";
import * as webSocketClientPEI from '../../dashlets/PEIChartBagDashlet/webSocketClient';
import { showErrorNotification, showInfoNotification } from "components/toastr/notificationActions";

const getDashletTypesDropdownOptions = () => {
    return _.concat({value: '', label: ''}, _.map(getDashletTypes(), (dashletType) => {
        return {value: dashletType, label: getDashletTypeLabel(dashletType)}
    }))
};

const getEmptyDashletData = () => {
    return {
        dashboardId: null,
        type: '',
        name: '',
        config: {}, //type specific fields
        timeRange: null,
        accordionId: null
    }
};

const getEmptyDashletErrors = () => {
    return {};
};

const getEmptyDashletConfigOptions = () => {
    return {};
};

const initialState = {
    dashletTypesDropdownOptions: [],
    isCreateDashletOpened: false,
    isCreateDashletLocked: false,
    createDashletSteps: ['Set Dashlet Title and Select Type', 'Configure Dashlet'],
    activeCreateDashletStep: 0,

    dashletDashboardId: null,
    dashletType: null,
    dashletName: null,

    peiChartBagTemplates: [],
    selectedPEIChartBagTemplate: null,

    dashletData: getEmptyDashletData(),
    dashletErrors: getEmptyDashletErrors(),
    dashletConfigOptions: getEmptyDashletConfigOptions(),

    isOpenImportDashletModal: false
};

const createDashletSlice = createSlice({
    name: 'createDashlet',
    initialState,
    reducers: {
        openCreateDashlet: {
            reducer(state, action) {
                const {dashboardId, accordionId} = action.payload;
                state.isCreateDashletOpened = true;
                state.dashletData.dashboardId = dashboardId;
                state.dashletData.accordionId = accordionId;
                state.dashletTypesDropdownOptions = getDashletTypesDropdownOptions();
            },
            prepare(dashboardId, accordionId) {
                return {
                    payload: {dashboardId, accordionId}
                }
            }
        },
        closeCreateDashlet(state) {
            state.isCreateDashletOpened = false;
            state.isCreateDashletLocked = false;
            state.activeCreateDashletStep = 0;
            state.dashletData = getEmptyDashletData();
            state.dashletErrors = getEmptyDashletErrors();
            // state.dashletConfigOptions = getEmptyDashletConfigOptions();
        },
        setIsCreateDashletLocked(state, action) {
            state.isCreateDashletLocked = action.payload;
        },
        setActiveStep(state, action) {
            state.activeCreateDashletStep = action.payload;
        },
        setDashletDataProperty: {
            reducer(state, action) {
                const {propertyName, propertyValue} = action.payload;
                state.dashletData[propertyName] = propertyValue;
            },
            prepare(propertyName, propertyValue) {
                return {
                    payload: {propertyName, propertyValue}
                }
            }
        },
        setDashletDataConfigProperty: {
            reducer(state, action) {
                const {propertyName, propertyValue} = action.payload;
                state.dashletData.config[propertyName] = propertyValue;
            },
            prepare(propertyName, propertyValue) {
                return {
                    payload: {propertyName, propertyValue}
                }
            }
        },
        setDashletOptionsProperty: {
            reducer(state, action) {
                const {propertyName, propertyValue} = action.payload;
                if (propertyValue === undefined) {
                    state.dashletConfigOptions[propertyName.propertyName] = propertyName.propertyValue;
                } else {
                    state.dashletConfigOptions[propertyName] = propertyValue;
                }
            },
            prepare(propertyName, propertyValue) {
                return {
                    payload: {propertyName, propertyValue}
                }
            }
        },
        setDashletErrors(state, action) {
            state.dashletErrors = action.payload;
        },
        setIsOpenImportDashletModal(state, action) {
            state.isOpenImportDashletModal = action.payload;
        },
        setSelectedDashboardId(state, action) {
            state.dashletData.dashboardId = action.payload;
        }
    }
});

export const {
    openCreateDashlet,
    closeCreateDashlet,
    setIsCreateDashletLocked,
    setActiveStep,
    setDashletDataProperty,
    setDashletDataConfigProperty,
    setDashletOptionsProperty,
    setDashletErrors,
    setIsOpenImportDashletModal,
    setSelectedDashboardId
} = createDashletSlice.actions;

export default createDashletSlice.reducer;

export const changeActiveStep = activeStep => (dispatch, getState) => {
    if (activeStep === 1) {
        const errors = validateCommonDashletData(createDashletDataSelector(getState()));
        if (_.keys(errors).length > 0) {
            dispatch(setDashletErrors(errors));
        } else {
            dispatch(setActiveStep(activeStep));
        }
    } else {
        dispatch(setActiveStep(activeStep));
    }
};



const validateCommonDashletData = (dashletData) => {
    const errors = {};
    if (!_.trim(dashletData.name)) {
        errors.name = true;
    }
    if (!dashletData.type) {
        errors.type = true
    }

    return errors;
};

const validateDashletData = (dashletData) => {
    const commonErrors = validateCommonDashletData(dashletData);

    //validation depends on dashlet type
    let configErrors = {};
    const validateConfigFunc = getValidateConfigFunc(dashletData.type);
    if (getValidateConfigFunc(dashletData.type)) {
        configErrors = validateConfigFunc(dashletData.config);
    }

    return {
        ...commonErrors,
        ...configErrors
    }
};

export const submitCreateDashlet = () => (dispatch, getState) => {
    let dashletData = getState().createDashlet.dashletData;

    const buildDashletObjectFunc = getBuildDashletObjectFunc(dashletData.type);
    if (buildDashletObjectFunc) {
        dashletData = buildDashletObjectFunc(dashletData, getState().createDashlet.dashletConfigOptions);
    }

    const validationErrors = validateDashletData(dashletData);
    if (_.keys(validationErrors).length > 0) {
        dispatch(setDashletErrors(validationErrors));
    } else {
        dispatch(createDashlet(dashletData));
        dispatch(closeCreateDashlet());
    }
}

export const onImportDashlet = (dashboardId) => (dispatch, getState) => {
    dispatch(setSelectedDashboardId(dashboardId));
    dispatch(setIsOpenImportDashletModal(true));
}

export const importDashlet =
  (file, selectedBackendKey, selectedBaceknd) => (dispatch, getState) => {
    if (selectedBaceknd == "HLC") {
      const importDashletStompFrame = {
        base64: file.base64,
      };
      webSocketClient.importDashlet(getState(), selectedBackendKey, importDashletStompFrame);
    } else {
      const importDashletStompFrame = {
        base64: file.base64,
      };
      webSocketClientPEI.importPEIDashlet(getState(), selectedBackendKey, importDashletStompFrame);
    }
}

export const handleImportDashlet = (response) => (dispatch, getState) => {
    const {hasErrors, errorMessage} = response;
    if (!hasErrors) {
        const state = getState();
        if (!response.config.type.includes("PEI")) {
            const dashletConfig = {...response.config.config}
            let accordionId = null;
            let dashboardId = state.createDashlet.dashletData.dashboardId?.id;
            if (dashboardId) {
                const accordionPanels = getState().dashboard.dashboardTree.entities.dashboards[dashboardId].accordionPanels;
                accordionId = accordionPanels[Object.keys(accordionPanels)[0]].id;   
            }
            const dashletData = {
                config: dashletConfig,
                settings: response.config.dashletSettings,
                timeRange: response.config.dashletTimeRange,
                type: response.config.type,
                dashboardId: dashboardId,
                accordionId,
                name: response.config.name
            }

            dispatch(createDashlet(dashletData));
            dispatch(setIsOpenImportDashletModal(false));
        } else {
            const dashletConfig = {...response.config.config}
            let accordionId = null;
            let dashboardId = state.createDashlet.dashletData.dashboardId?.id;

            if (dashboardId) {
                const accordionPanels = getState().dashboard.dashboardTree.entities.dashboards[dashboardId].accordionPanels;
                accordionId = accordionPanels[Object.keys(accordionPanels)[0]].id;   
            }
            const dashletData = {
                config: dashletConfig,
                settings: response.config.chartBagDashletSettings,
                timeRange: response.config.dashletTimeRange,
                type: response.config.type,
                dashboardId: dashboardId,
                accordionId,
                name: response.config.templateName
            }
            dispatch(createDashlet(dashletData));
            dispatch(setIsOpenImportDashletModal(false));

            if (response.result) {
                showInfoNotification(response.templateName + " : "+ response.message);
            } else {
                showErrorNotification(response.templateName + " : "+ response.message);
            }
        }
    } else {
        showErrorNotification("Import Dashlet", errorMessage);
    }
}

export const selectTemplateNames = (state) => {
    let templates = state.createDashlet.dashletConfigOptions.templates
    if (templates)
        return templates.map(t => t.name)
}

export const selectTemplates = (state) => {
    let templates = state.createDashlet.dashletConfigOptions.templates
    if (templates)
        return templates;
}

export const selectTemplateName = (state) => {
    let templateNames = state.createDashlet.dashletConfigOptions.templates
    if (templateNames)
     return templateNames
}

export const selectReportNames = (state) => {
    return state.createDashlet.dashletConfigOptions.reportNames
}

export const selectTargetOptions = (state) => {
    return state.createDashlet.dashletConfigOptions.targetOptions
}

export const selectTargetNames = (state) => {
    return state.createDashlet.dashletConfigOptions.targetOptions?.map(opt => opt.targetName)
}