import {createSlice, PayloadAction} from "@reduxjs/toolkit";


interface BackendsLogsState {
    backendKey: string | null;
    lines: string[];
    loading: boolean;
    error: string | null;
}

const initialState: BackendsLogsState = {
    backendKey: null,
    lines: [],
    loading: false,
    error: null,
};

const PEIbackendsLogsSlice = createSlice({
    name: "PEIbackendsLogs",
    initialState,
    reducers: {
        setPEIBackendsLogsLines(state, action: PayloadAction<string[]>) {
            const logLines = action.payload;
            state.lines = logLines.length === 0 ? logLines : (state.lines.length > 2000) ? logLines : state.lines.concat(logLines);
        },
        setPEIBackendsLogsLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setPEIBackendsLogsBackendKey(state, action: PayloadAction<string>) {
            state.backendKey = action.payload;
            // clear results
            state.lines = [];
        },
    }
});

export const {
    setPEIBackendsLogsLines,
    setPEIBackendsLogsLoading,
    setPEIBackendsLogsBackendKey
} = PEIbackendsLogsSlice.actions;

export default PEIbackendsLogsSlice.reducer;

export const selectPEIBackendsLogsBackendKey = (state: any) => state.PEIbackendsLogs.backendKey;
export const selectPEIBackendsLogsLines = (state: any) => state.PEIbackendsLogs.lines;
