import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import {ForgotPasswordLink, LoginLink } from '../../../routes/links';
import {Container} from "@material-ui/core";
import {AuthFormInput, AuthSubmitButton, AuthLogo, AuthLinkBordered} from "../../../components/gate/Auth/Auth";
import ErrorMessage from "../../../components/gate/Auth/ErrorMessage";
import AuthFormHeader from "../../../components/gate/Auth/AuthFormHeader";
import {authFormStyles} from "../../../jss/gate/Auth/authStyles";
import AuthLink from "../../../components/gate/Auth/AuthLink";
import BottomHint from "../../../components/gate/Auth/BottomHint";
import { HLC_WEB_SOCKET_URL, IS_HLC_WS_ENABLED, IS_PEI_WS_ENABLED, PEI_WEB_SOCKET_URL } from 'config';
import StompClient from 'websocket/StompClient';
import { STOMP_CLIENT } from 'reducers/webSocketSlice';
import PEIChartBagWebSocketSubscription from 'dashlets/PEIChartBagDashlet/components/PEIChartBagWebSocketSubscription';

class Registration extends Component {
  render() {
    const {classes, errorMessage, disabled, onRegister, onChangeInputHandler} = this.props;

    return (
      <Container id="login-container" className={classes.container}>
        <main className={classes.main}>
          <Paper className={classes.paper}>
            <AuthLogo />
            <AuthFormHeader title="Registration"/>

            <ErrorMessage message={errorMessage}/>

            <form className={classes.form}>
              <FormControl margin="normal" fullWidth className={classes.formControl}>
                <AuthFormInput id="fullName" name="name" autoComplete="name" autoFocus className={classes.input} placeholder="Your name" onChange={onChangeInputHandler} disabled={disabled} />
              </FormControl>
              <FormControl margin="normal" fullWidth className={classes.formControl}>
                <AuthFormInput id="email" name="username" autoComplete="email" className={classes.input} placeholder="E-mail" onChange={onChangeInputHandler} disabled={disabled} />
              </FormControl>
              <FormControl margin="normal" fullWidth className={classes.formControl}>
                <AuthFormInput name="password" type="password" id="password" autoComplete="current-password" className={classes.input} placeholder="Password" onChange={onChangeInputHandler} disabled={disabled} />
              </FormControl>

              <AuthSubmitButton
                type="submit"
                fullWidth
                // variant="contained"
                onClick={onRegister}
                disabled={disabled}
              >
                Register
              </AuthSubmitButton>
            </form>

            <Container className={classes.linksContainer}>
              <AuthLinkBordered label="Sign in" actionComponent={LoginLink}/>
              <AuthLink label="Forgot Password?" actionComponent={ForgotPasswordLink}/>
            </Container>

            <BottomHint />
          </Paper>
        </main>
        {/* {IS_PEI_WS_ENABLED ? [
          <StompClient
            key="hlc-ws-stomp-client-1582840873831"
            stompClientKey={STOMP_CLIENT.PEI}
            sockJsEndpoint={PEI_WEB_SOCKET_URL}
            debugMode
          />,
          <PEIChartBagWebSocketSubscription />
        ] : null} */}
        {IS_HLC_WS_ENABLED ? [
          <StompClient
            key="pei-ws-stomp-client-1582840873831"
            stompClientKey={STOMP_CLIENT.HLC}
            sockJsEndpoint={HLC_WEB_SOCKET_URL}
            debugMode
          />,
          <PEIChartBagWebSocketSubscription />
        ] : null}
      </Container>
    );
  }
}

Registration.propTypes = {
  //data
  classes: PropTypes.object,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,

  //func
  onRegister: PropTypes.func.isRequired,
  onChangeInputHandler: PropTypes.func.isRequired,
};

export default withStyles(authFormStyles)(Registration);