import Typography from "@material-ui/core/Typography";
import * as _ from 'lodash';
import Moment from 'moment';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Dimensions from 'react-dimensions';
import {connect} from 'react-redux';
import LoadingProgress from "../../../components/dashboard/dashlets/LoadingProgress";
import {setExtremes, setXExtremes, refresh, setDashletFrameSize} from "../actions";
import PEIChartBagDashlet from "./PEIChartBagDashlet";
import { Scrollbars } from 'react-custom-scrollbars';
import { setIsChartDataLoading } from "reducers/dashboard/dashboardSlice";

const newChartsGroup = (key) => {
  return {
    key: key,
    chartTitle: '',
    yAxisTitle: '',
    metric: '',
    databaseName: '',
    title: '',
    subtitle: '',
    chartSeriesList: []
  }
};

const newChartSeriesInfo = () => {
  return {
    instanceId: null,
    module: null,
    sqlId: null,
    measurement: null,
    isHistorical: false,
  }
};

const buildSubtitle = (snapshotPares) => {
  let subtitle = `Chart period between ${snapshotPares.snapPare.sSnapTimeStart} and ${snapshotPares.snapPare.sSnapTimeEnd}`;
  if (snapshotPares.historicalSnapPare) {
    let historicalSubtitle = `Superimposed period between ${snapshotPares.historicalSnapPare.sSnapTimeStart} and ${snapshotPares.historicalSnapPare.sSnapTimeEnd}`;
    subtitle = `${subtitle}<br/>${historicalSubtitle}`;
  }

  return subtitle
};

let firstInitialLoad = {};

const buildSplitChartsGroupList = (dataCurrentMap, dataPrevMap, snapshotPares, historicalOffset) => {
  const chartsGroupList = [];

  if (!_.isEmpty(dataCurrentMap)) {
    _.each(_.keys(dataCurrentMap), (name) => {
      const dataCurrent = dataCurrentMap[name];

      const chartsGroup = newChartsGroup(name);

      if (!_.isEmpty(dataCurrent)) {
        let label = name.substring(name.indexOf("/") + 1, name.length);
        chartsGroup.title = label;

        const seriesInfo = newChartSeriesInfo();
        chartsGroup.chartSeriesList.push({
          name: name,
          info: seriesInfo,
          data: dataCurrent.map((dataValue) => {
            return [dataValue.dataDate, dataValue.dataValue];
          }),
        });

        //historical
        const dataPrev = !_.isEmpty(dataPrevMap) ? dataPrevMap[name] : null;
        if (dataPrev?.length > 0) {
          chartsGroup.chartSeriesList.push({
            name: `${label}/Historical`,
            info: {
              ...seriesInfo,
              isHistorical: true
            },
            data: dataPrev.map((dataValue) => {
              return [dataValue.dataDate + historicalOffset, dataValue.dataValue];
            })
          })
        }
      }

      if (!_.isEmpty(chartsGroup.chartSeriesList)) {
        chartsGroupList.push(chartsGroup);
      }
    })
  }

  //add subtitle for the 1st chart group
  if (!_.isEmpty(chartsGroupList)) {
    chartsGroupList[0].subtitle = buildSubtitle(snapshotPares)
  }

  return chartsGroupList;
};

const buildNotSplitChartsGroupList = (dataCurrentMap, dataPrevMap, snapshotPares, historicalOffset) => {
  const chartsGroupList = [];

  const chartsGroup = newChartsGroup('group_all');
  if (!_.isEmpty(dataCurrentMap)) {
    _.each(_.keys(dataCurrentMap), (name) => {
      const dataCurrent = dataCurrentMap[name];

      const label = name;
      if (!_.isEmpty(dataCurrent)) {
        const seriesInfo = newChartSeriesInfo();
        chartsGroup.chartSeriesList.push({
          name: name,
          info: seriesInfo,
          data: dataCurrent.map((dataValue) => {
            return [dataValue.dataDate, dataValue.dataValue];
          }),
        });

        //historical
        const dataPrev = !_.isEmpty(dataPrevMap) ? dataPrevMap[name] : null;
        if (dataPrev) {
          chartsGroup.chartSeriesList.push({
            name: `${label}/Historical`,
            info: {
              ...seriesInfo,
              isHistorical: true
            },
            data: dataPrev.map((dataValue) => {
              return [dataValue.dataDate + historicalOffset, dataValue.dataValue];
            })
          })
        }
      }
    });

    if (!_.isEmpty(chartsGroup.chartSeriesList)) {
      chartsGroupList.push(chartsGroup);
    }

    if (!_.isEmpty(chartsGroupList)) {
      chartsGroupList[0].subtitle = buildSubtitle(snapshotPares)
    }

  }

  return chartsGroupList;
};

class PEIChartBagDashletContainer extends Component {

  componentDidMount() {
    const { dashlet, containerWidth, containerHeight, isResizing, setDashletFrameSize, refresh, isDashletPreviewOpen, setIsChartDataLoading } = this.props;
    if (!isDashletPreviewOpen)
      setDashletFrameSize(dashlet.id, containerWidth, containerHeight);
    if (!firstInitialLoad[dashlet.id] || isDashletPreviewOpen) {
      setIsChartDataLoading(true)
      refresh(dashlet, true, containerWidth, containerHeight);
      firstInitialLoad[dashlet.id] = true;
    }
  }

  componentDidUpdate(prevProps) {
    const { data, dashlet, containerWidth, containerHeight, isResizing, setDashletFrameSize, refresh, isDashletPreviewOpen, setIsChartDataLoading} = this.props;
    if ((!isResizing && (data.frameWidth !== prevProps.containerWidth || data.frameHeight !== prevProps.containerHeight))) {
      if (!isDashletPreviewOpen) {
        setDashletFrameSize(dashlet.id, containerWidth, containerHeight);
        setIsChartDataLoading(true)
        refresh(dashlet, true, containerWidth, containerHeight);
      }
    }
  }

  componentWillUnmount() {
    const { dashlet, refresh, setIsChartDataLoading, isDashletPreviewOpen} = this.props;
    if (isDashletPreviewOpen) {
      setIsChartDataLoading(true)
      refresh(dashlet, true, 0, 0);
    }
  }

  setExtremes = (key, xMin, xMax, yMin, yMax) => {
    this.props.setExtremes(this.props.dashlet.id, key, xMin, xMax, yMin, yMax);
  };

  setXExtremes = (xMin, xMax, isManualZoom) => {
    this.props.setXExtremes(this.props.dashlet.id, xMin, xMax, isManualZoom);
  };

  render() {
    const {
      dashlet: {
        settings: {
        },
      },
      data,
      containerHeight,
      containerWidth
    } = this.props;

    if (data && data.isLoading) {
      return (
          <LoadingProgress />
      )
    }

    if (!data || !data.base64ImageChart) {
      return (
          <Typography variant="body2">No data to display</Typography>
      );
    }

    return (
      this.props.isChartDataLoading ? <LoadingProgress isDashletPreviewOpen={this.props.isDashletPreviewOpen} /> :
      <Scrollbars style={{ height: this.props.isDashletPreviewOpen ? containerHeight : containerHeight + 10}}>
        <img src={`data:image/gif;base64,${data.base64ImageChart}`} />
        </Scrollbars>
    );
  }
}

PEIChartBagDashletContainer.propTypes = {
  //data
  dashlet: PropTypes.object.isRequired,
  data: PropTypes.shape({
    isShowChart: PropTypes.bool,
    chartData: PropTypes.object,
    extremes: PropTypes.object,
    xMin: PropTypes.number,
    xMax: PropTypes.number,
    isResizing: PropTypes.bool,
  }),
  containerHeight: PropTypes.number,
  containerWidth: PropTypes.number,
  //...

  //func
  setDashletFrameSize: PropTypes.isRequired,
  refresh:PropTypes.isRequired,
  setExtremes: PropTypes.func.isRequired,
  setXExtremes: PropTypes.func.isRequired,
  setIsChartDataLoading: PropTypes.func,
  //...
};

const mapStateToProps = (state) => {
  return {
    isDashletPreviewOpen: state.dashboard.isDashletPreviewOpen,
    isChartDataLoading: state.dashboard.isChartDataLoading,
    //...
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    setExtremes: (dashletId, key, xMin, xMax, yMin, yMax) => {
      dispatch(setExtremes(dashletId, key, xMin, xMax, yMin, yMax));
    },
    setXExtremes: (dashletId, xMin, xMax, isManualZoom) => {
      dispatch(setXExtremes(dashletId, xMin, xMax, isManualZoom));
    },
    setDashletFrameSize: (dashletId, width, height) => {
      dispatch(setDashletFrameSize(dashletId, width, height));
    },
    refresh: (dashlet, isResizeOnly, containerWidth, containerHeight) => {
      dispatch(refresh(dashlet, isResizeOnly, containerWidth, containerHeight));
    },
    setIsChartDataLoading: (value) => {
      dispatch(setIsChartDataLoading(value))
    }
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dimensions({elementResize: true})(PEIChartBagDashletContainer))
