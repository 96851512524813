import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import CustomTextField from "components/common/TextField/TextField";
import GenAIIntegrationConfigProps from "./GenAIIntegrationConfigProps.types";
import OpenAI from "openai";
import { makeStyles, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { showSuccessNotification, showWarningNotification } from "components/toastr/notificationActions";
import { FETCH_MODEL_FOR_DEEPSEEK, FETCH_MODEL_FOR_GROKEAI, FETCH_MODEL_FOR_GEMINIAI } from 'constants/AIRequestApi';
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { selectIntegrationsBackendKey } from "reducers/settingsSlice";
import { selectStompClient } from "reducers/webSocketSlice";
import { listAiModels } from "actions/settings/integrationActions";
import { LIST_AI_MODEL_SETTINGS_SUBSCRIPTION } from "api/websocket/hlc/integrationWebSocketClient";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";

const useStyles = makeStyles({
    loadingItem: {
        padding: '8px 12px',
        textAlign: 'center',
        color: '#999',
      },
    borderStyle: {
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '0px',
    },
  });

const OpenAIGenAIIntegrationConfig: React.FC<GenAIIntegrationConfigProps> = ({
                                                                                       integration,
                                                                                       handleChangeProperty,
                                                                                       errors
                                                                                   }) => {
    const {enabled, openAIApiKey, openAIModel, genAIType, anthropicVersion} = integration;
    const [models, setModels] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    const dispatch = useDispatch();
    const backendKey: string | null = useSelector(selectIntegrationsBackendKey);
    const stompClient = useSelector(selectStompClient);

    const listAiModel = () => {
        if (backendKey && stompClient) {
            dispatch(listAiModels(backendKey, genAIType, openAIApiKey, anthropicVersion ?? ""));
        }
    }

    const listAiModelResult = (response: any) => {
        const {hasErrors, errorMessage, errorDetails} = response;
        if (!hasErrors) {
            setModels(response.modalName);
            showSuccessNotification("Model fetched successfully.");
        } else {
            showWarningNotification("Failed to fetch models. Please check your API key and try again. : ", errorMessage);
        }

    }

    const fetchModels = async () => {
        if (!openAIApiKey) return;
        setModels([]);
        setLoading(true);

        const openai = new OpenAI({
            apiKey: openAIApiKey,
            dangerouslyAllowBrowser: true
        });

        try {
            const response = await openai.models.list();
            const modelIds = response.data.map((model: any) => model.id);

            setModels(modelIds);
        } catch (error) {
            showWarningNotification("Failed to fetch models. Please check your API key and try again.")
        } finally {
            setLoading(false);
        }
    };

    const fetchModelsForDeepSeek = async () => {
        if (!openAIApiKey) return;
        setModels([]);
        try {
            const anthropic = new OpenAI({
                apiKey: openAIApiKey, 
                dangerouslyAllowBrowser: true,
                baseURL: FETCH_MODEL_FOR_DEEPSEEK
            });

            const response = await anthropic.models.list();
            const modelIds = response.data.map((model: any) => model.id);
            setModels(modelIds);
        } catch (error) {
            console.log(error);
            showWarningNotification("Failed to fetch models. Please check your API key and try again.")
        }
    }

    const fetchModelsForClaudeAI = async () => {
        if (!openAIApiKey) return;
        setModels([]);
        listAiModel();
        // try {
        //     let version = anthropicVersion ? anthropicVersion : '2023-06-01';

        //     const response = await Axios.get(FETCH_MODEL_FOR_CLAUDEAI, {
        //         headers: {
        //             'x-api-key': openAIApiKey,
        //             'anthropic-version': version,
        //             'Content-Type': 'application/json',
        //         },
        //     });
        //     const modelIds = response.data.map((model: any) => model.id);
        //     setModels(modelIds);
        // } catch (error) {
        //     console.log(error);
        //     showWarningNotification("Failed to fetch models. Please check your API key and try again.")
        // }
    }

    const fetchModelsForLlamaAI = async () => {
        if (!openAIApiKey) return;
        setModels([]);
        listAiModel();
        // try {
            // const response = await Axios.get(FETCH_MODEL_FOR_LLAMAAI, {
        //         headers: {
        //             'Authorization': `Bearer ${openAIApiKey}`,
        //             'Content-Type': 'application/json',
        //         },
        //     });
        //     const modelIds = response.data.map((model: any) => model.id);
        //     setModels(modelIds);
        // } catch (error) {
        //     console.log(error);
        //     showWarningNotification("Failed to fetch models. Please check your API key and try again.")
        // }
    }

    const fetchModelsForGrokAI = async () => {
        if (!openAIApiKey) return;
        setModels([]);
        const openai = new OpenAI({
            apiKey: openAIApiKey,
            dangerouslyAllowBrowser: true,
            baseURL: FETCH_MODEL_FOR_GROKEAI
        });

        try {
            const response = await openai.models.list();
            const modelIds = response.data.map((model: any) => model.id);

            setModels(modelIds);
        } catch (error) {
            showWarningNotification("Failed to fetch models. Please check your API key and try again.")
        }
    };

    const fetchModelsForGeminiAI = async () => {
        if (!openAIApiKey) return;
        setModels([]);
        try {
            const response = await Axios.get(FETCH_MODEL_FOR_GEMINIAI, {
                params: {
                    key: openAIApiKey,
                },
            });
            
            const modelIds = response.data.models.map((model: any) => model.name.replace("models/", ""));;

            setModels(modelIds);
        } catch (error) {
            showWarningNotification("Failed to fetch models. Please check your API key and try again.")
        }
    };

    return (
        <>
            <Grid item container spacing={2} xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={2} style={{textAlign: "left"}}>
                    <InputLabel htmlFor="apiKey">API Key</InputLabel>
                </Grid>
                <Grid item xs={5}>
                    <CustomTextField
                        disabled={!enabled}
                        name="openAIApiKey"
                        fullWidth
                        textAlign="left"
                        value={openAIApiKey}
                        onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        onBlur={() => {
                            if (openAIApiKey) {
                                if (genAIType == "OpenAI")
                                    fetchModels();
                                else if(genAIType == "DeepSeekAI")
                                    fetchModelsForDeepSeek();
                                else if(genAIType == "ClaudeAI")
                                    fetchModelsForClaudeAI();
                                else if(genAIType == "LLAMAAI")
                                    fetchModelsForLlamaAI();
                                else if(genAIType == "GrokAI")
                                    fetchModelsForGrokAI();
                                else if(genAIType == "geminiAI")
                                    fetchModelsForGeminiAI();
                            }
                        }}
                    />
                    {enabled && errors.openAIApiKey &&
                        <div style={{color: 'red', marginTop: '4px', textAlign: 'left'}}>{errors.openAIApiKey}</div>}
                </Grid>
            </Grid>
            {genAIType == "ClaudeAI" && <Grid item container spacing={2} xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={2} style={{textAlign: "left"}}>
                    <InputLabel htmlFor="anthropic-version">anthropic-version</InputLabel>
                </Grid>
                <Grid item xs={5}>
                    <CustomTextField
                        name="anthropicVersion"
                        fullWidth
                        textAlign="left"
                        value={anthropicVersion}
                        onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                    />
                    {enabled && errors.openAIApiKey &&
                        <div style={{color: 'red', marginTop: '4px', textAlign: 'left'}}>{errors.openAIApiKey}</div>}
                </Grid>
            </Grid>}
            <Grid item container spacing={2} xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={2} style={{textAlign: "left"}}>
                    <InputLabel htmlFor="modalName">Model Name</InputLabel>
                </Grid>
                <Grid item xs={5}>
                    <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={models}
                        onChange={(event: any, newValue : any) => {
                            handleChangeProperty("openAIModel", newValue);
                        }}
                        onInputChange={(event: any, newInputValue) => {
                            handleChangeProperty("openAIModel", newInputValue);
                        }}
                        disabled={!enabled}
                        loading={loading}
                        value={openAIModel || ""}
                        ListboxProps={{
                            style: { 
                                maxHeight: "200px", 
                                overflowY: "auto",
                                border: "1px solid #ccc", 
                                borderRadius: "4px",
                                backgroundColor: "white",
                                scrollbarWidth: 'thin',
                                scrollbarColor: '#DBDBDB',
                            },
                        }}
                        renderInput={(params) =>  (
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    className: classes.borderStyle
                                }}
                            />
                        )}
                    />
                    {enabled && errors.openAIModel &&
                        <div style={{color: 'red', marginTop: '4px', textAlign: 'left'}}>{errors.openAIModel}</div>}
                </Grid>
            </Grid>

            <HLCUserSessionWebSocketSubscription
                subscriptionDestination={LIST_AI_MODEL_SETTINGS_SUBSCRIPTION}
                handleMessage={(response) => listAiModelResult(response)}
            />
        </>
    );
};

export default OpenAIGenAIIntegrationConfig;
