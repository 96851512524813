import {WEB_SOCKET_ACTION_PREFIX, WEB_SOCKET_SUBSCR_PREFIX} from "constants/webSocketConstants";
import {publish} from "./peiCommonWebSocketClient";
import LoadLogsStompFrame from "../../../types/payload/infrastructure/logs/LoadLogsStompFrame";

export const LOAD_PEI_LOGS_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.logs.load`;

export const loadLogsPEI = (appState: unknown, backendKey: string, payload: LoadLogsStompFrame) =>
    publish(appState, backendKey, LOAD_PEI_LOGS_ACTION, payload);

export const LOGS_PEI_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.logs`;

