import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import notificationSchema from "schemas/notificationSchema";
// import axios from "axios";

// Async action to fetch notifications for a user
// export const fetchNotifications = createAsyncThunk(
//   "notifications/fetchNotifications",
//   async (userId) => {
//     const response = await axios.get(`/api/notifications/${userId}`);
//     return response.data;
//   }
// );

interface Notification {
  id: string;
  userId: string;
  title: string;
  isRead: boolean;
  isDeleted: boolean;
  isView: boolean;
  createdAt: string;
}

interface NotificationsState {
  entities: Record<string, Notification>;
  ids: string[];
  loading: boolean;
  error: string | null;
  notificationCounts: Record<string, number>;
}

const initialState: NotificationsState = {
  entities: {},
  ids: [],
  // entities: {
  //   "1": { id: "1", userId: "1", title: "New Alert 1", isRead: false, isDeleted: false, isView: false, createdAt: "2025-03-04T12:00:00Z" },
  //   "2": { id: "2", userId: "1", title: "New Alert 2", isRead: false, isDeleted: false, isView: false, createdAt: "2025-03-04T12:05:00Z" },
  //   "3": { id: "3", userId: "1", title: "New Alert 3", isRead: true, isDeleted: false, isView: false, createdAt: "2025-03-04T12:10:00Z" },
  //   "4": { id: "4", userId: "1", title: "Important Notice: PEI Backend is now integrated with our HLC Application. We are requesting you to download latest backend file again and restart the application.", isRead: false, isDeleted: false, isView: false, createdAt: "2025-03-04T12:15:00Z" }
  // },
  // ids: ["1", "2", "3", "4"],
  notificationCounts: {},
  loading: false,
  error: null,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotificationsLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setNotifications(state, action: PayloadAction<Notification[]>) {
      const normalizedData = normalize(action.payload, [notificationSchema]);
      state.entities = normalizedData.entities.notifications || {};
      state.ids = normalizedData.result;
    },
    setNotificationsCount: {
      reducer(state, action: any) {
        state.notificationCounts = action.payload;
      },
      prepare(counts: any) {
        return { payload: counts };
      },
    },
    markAsRead: {
      reducer(state, action: PayloadAction<{ id: string }>) {
        const { id } = action.payload;
        if (state.entities[id]) {
          state.entities[id].isRead = true;
        }
      },
      prepare(id: string) {
        return { payload: { id } };
      },
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchNotifications.pending, (state) => {
  //       state.loading = true;
  //     })
  //     .addCase(fetchNotifications.fulfilled, (state, action) => {
  //       state.loading = false;
  //       const normalizedData = normalize(action.payload, [notificationSchema]);
  //       state.entities = normalizedData.entities.notifications || {};
  //       state.ids = normalizedData.result;
  //     })
  //     .addCase(fetchNotifications.rejected, (state, action) => {
  //       state.loading = false;
  //       state.error = action.error.message;
  //     });
  // },
});

export const { setNotificationsLoading, setNotifications, markAsRead, setNotificationsCount } = notificationsSlice.actions;

export default notificationsSlice.reducer;

// Selectors
export const selectNotificationsEntities = (state: any) => state.notifications.entities;
export const selectNotificationsIds = (state: any) => state.notifications.ids;
export const selectNotificationsLoading = (state: any) => state.notifications.loading;
export const selectNotificationsCounts = (state: any) => state.notifications.notificationCounts;
