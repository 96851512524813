import {selectIsHPEItompClientConnected, selectStompClient} from "../../../reducers/webSocketSlice";
import {BACKEND_KEY_HEADER_NAME, USER_SECRET, USER_SESSION_ID_HEADER_NAME, WEBSOCKET_USER_CLIENT_HEADER} from "../../../constants/webSocketConstants";
import { getUserSessionId } from "actions/userInfoActions";
import { selectUserSecret } from "reducers/userInfoSlice";


export const STOMP_CLIENT = {
    PEI: "PEI"
};
export const publish = (appState, backendKey, destination, payload) => {
    if(appState.webSocket.stompClient) {
        selectStompClient(appState).publish({
            destination: destination,
            body: JSON.stringify(payload || {}),
            headers: {
                [BACKEND_KEY_HEADER_NAME]: backendKey,
                [USER_SESSION_ID_HEADER_NAME]: getUserSessionId(),
                [USER_SECRET]: selectUserSecret(appState),
                ...WEBSOCKET_USER_CLIENT_HEADER
            }
        });
    }
}