import {createSlice} from "@reduxjs/toolkit";
import {createSelector} from "reselect";

export const STOMP_CLIENT = {
    HLC: "HLC",
    PEI: "PEI"
};

const initialState = {
    stompClient: null,
    stompClients: {},
    subsciptionPaths: [],
};

const webSocketSlice = createSlice({
    name: 'webSocket',
    initialState,
    reducers: {
        setStompClient: {
            reducer(state, action) {
                const {stompClientKey, stompClient} = action.payload;
                state.stompClient = stompClient;
                // state.stompClients[stompClientKey] = stompClient;
                // state.stompClients["PEI"] = stompClient;
            },
            prepare(stompClientKey, stompClient) {
                return {
                    payload: {stompClientKey, stompClient}
                }
            },
        },
        setSubscriptionPaths: {
            reducer(state, action) {
                const { subsciptionPaths } = action.payload;
                state.subsciptionPaths = subsciptionPaths;
            },
            prepare(subsciptionPaths) {
                return {
                    payload: {subsciptionPaths}
                }
            }
        },
    }
});

export const {setStompClient, setSubscriptionPaths} = webSocketSlice.actions;

export default webSocketSlice.reducer;

/**
 * ==========
 * Selectors
 * ==========
 */

const selectStompClientByKey = createSelector(
    [
        state => state.webSocket.stompClients,
        (state, stompClientKey) => stompClientKey
    ],
    (stompClients, stompClientKey) => stompClients[stompClientKey]
);


export const selectStompClient = state => {
    if (!state) {
        console.error("selectStompClient: 'state' is null or undefined.");
        return null;
    }
    if (!state.webSocket) {
        console.error("selectStompClient: 'state.webSocket' is null or undefined.");
        return null;
    }
    if (!state.webSocket.stompClients) {
        console.error("selectStompClient: 'state.webSocket.stompClients' is null or undefined.");
        return null;
    }
    //return state.webSocket.stompClients[STOMP_CLIENT.];
    const stompClient = state.webSocket.stompClient;

    if (!stompClient) {
        console.error("selectStompClient: 'hlcStompClient' is null or undefined.");
    }
    else {
        if (typeof stompClient.getConnectionDetails === 'function') {
            console.log("stompClient Connection Details:", stompClient.getConnectionDetails());
        }
    }

    return stompClient;

};


export const selectHLCStompClient_old = state => state.webSocket.stompClient;

// export const selectHLCStompClient = state => {
//     if (!state) {
//         console.error("selectHLCStompClient: 'state' is null or undefined.");
//         return null;
//     }
//     if (!state.webSocket) {
//         console.error("selectHLCStompClient: 'state.webSocket' is null or undefined.");
//         return null;
//     }
//     if (!state.webSocket.stompClients) {
//         console.error("selectHLCStompClient: 'state.webSocket.stompClients' is null or undefined.");
//         return null;
//     }
//     //return state.webSocket.stompClient;
//     const hlcStompClient = state.webSocket.stompClient;

//     if (!hlcStompClient) {
//         console.error("selectHLCStompClient: 'hlcStompClient' is null or undefined.");
//     }
//     else {
//         // Log the Stomp client details for troubleshooting
//         // console.log("HLC StompClient Details:", hlcStompClient);
//         // If the connection details are properties on the client, log them:
//         // console.log("HLC StompClient Connection Details:", {
//         //     // Replace 'connectionDetail' with the actual property names
//         //     ip: hlcStompClient.connectionDetail?.ip,
//         //     port: hlcStompClient.connectionDetail?.port,
//         //     url: hlcStompClient.connectionDetail?.url,
//         //     // ... any other relevant details
//         // });
//         // If the Stomp client has methods to get connection details, call them:
//         if (typeof hlcStompClient.getConnectionDetails === 'function') {
//             console.log("HLC StompClient Connection Details:", hlcStompClient.getConnectionDetails());
//         }
//     }

//     return hlcStompClient;

// };

export const selectIsStompClientConnected = state => selectStompClient(state)?.connected;

// export const selectIsHLCStompClientConnected = state => selectHLCStompClient(state)?.connected;

// export const selectStompClient = state => state.webSocket.stompClient;
// export const selectIsStompClientConnected = state => state.webSocket.stompClient && state.webSocket.stompClient.connected;

export const selectSubscriptionPaths = state => state.webSocket.subsciptionPaths;