import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import ReactHighcharts from 'react-highcharts/ReactHighstock';
import HighchartsMap from 'highcharts-map';
// import regression from 'highcharts-regression';
import Boost from 'react-highcharts/node_modules/highcharts/modules/boost';
import Exporting from 'react-highcharts/node_modules/highcharts/modules/exporting';
import { generatePEIChart, handleClickOnPointPEI, handleLoadSelectedChartPEI } from 'actions/dashboardActions';
import {closeChartDialog, setExtremes} from 'reducers/dashboard/dashboardSlice'
import * as _ from "lodash";
import Moment from "moment";
import numeral from 'numeral';
import ChartsContainer from 'dashlets/HLCChartDashlet/components/ChartsContainer';
// import ChartsContainer from './ChartsContainer';

Boost(ReactHighcharts.Highcharts);
Exporting(ReactHighcharts.Highcharts);
// regression(ReactHighcharts.Highcharts);
HighchartsMap(ReactHighcharts.Highcharts);

const styles = theme => ({
    root: {
        maxWidth: '700px',
    },
    dialogContent: {
        width: '400px',
    },
    actionButtonLabel: {
        fontWeight: 600,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
});

class PEIChartTemplateContainer extends Component {

    componentDidMount() {
        this.props.generatePEIChart(this.props.dashletId);
    }

    handleResetZoom = () => {
        _.each(ReactHighcharts.Highcharts.charts, (chart) => {
            if (chart !== undefined) {
                chart.xAxis[0].setExtremes(
                    (chart.xAxis[0].dataMin - 5000),
                    chart.xAxis[0].dataMax + 5000,
                    undefined,
                    false,
                    {trigger: 'load'}
                );
                chart.yAxis[0].setExtremes(
                    chart.yAxis[0].dataMin,
                    chart.yAxis[0].dataMax,
                    undefined,
                    false,
                    {trigger: 'load'}
                  );
            }
          })
        this.props.setExtremes(null, null, null, null, null);
    }

    handleCancel = (event) => {
      this.props.close();
      this.props.closeChartDialog();
    };

    render() {
        const { chartData, isOpen, handleClickOnPoint, isRegressionTrend, xMin, xMax, setExtremes, 
          handleLoadSelectedChart } = this.props;
        let configList = [];
        if(chartData!==null) {
        _.map(chartData.chartsResult.chartsGroupList, (group, index) => {
            // if(group.yAxisTitle != null) {
            //   if(group.yAxisTitle.length > 11) {
            //     yaxisMargin = 4  
            //   } else {
            //     yaxisMargin = 20
            //   }
            // } else {
            //   yaxisMargin = 20
            // }
            // if(!isUniversalTimeLine) {
            //   if(isSplitTimeFrame || chartData.isReportExecution) {
            //     minTime = group.minTime - 5000;
            //     maxTime = group.maxTime + 5000;
            //   }
            // }
            let chartConfig =  {
              sectionId: group.sectionId,
              customOptions: {
                key: group.key,
                databaseName: group.databaseName,
              },
              mapNavigation: {
                enabled: true,
                enableButtons: false,
              },
              time: {
                useUTC: false,
              },
              boost: {
                // enabled: (chartData.chartType === ChartTypes.VRCA),
                enabled: false,
                useGPUTranslations: false,
                debug: {
                  timeSetup: true,
                  timeSeriesProcessing: true,
                  timeBufferCopy: true,
                  timeKDTree: true,
                  showSkipSummary: true
                }
              },
              credits: {
                enabled: false
              },
              rangeSelector: {
                enabled: false
              },
              navigator: {
                enabled: false,
              },
              navigation: {
                buttonOptions: {
                  enabled: false
                }
              },
              scrollbar: {
                enabled: false
              },
              xAxis: {
                min: group.minTime,
                max: group.maxTime,
                type: 'datetime',
                minRange: 1,
                ordinal: false
              },
              yAxis: {
                title: {
                  text: group.yAxisTitle,
                //   margin: yaxisMargin,
                },
                labels: {
                  x: -8,
                  y: 0
                },
                opposite: false
              },
              plotOptions: {
                series: {
                  cropThreshold: 0,
                  dataGrouping: {
                    enabled: chartData.chartsTemplate.options.isSmoothData,
                    approximation: 'average',
                    forced: true,
                    units: [[chartData.chartsTemplate.options.smoothDataMeasure.toLowerCase(), [chartData.chartsTemplate.options.smoothDataValue]]]
                  },
                  marker: {
                    enabled: true,
                    radius: 2
                  },
                  point: {
                    events: {
                        click: function (e) {
                            handleClickOnPoint(chartData.chartsTemplate.templateName);
                        }
                    }
                }
                }
              },
              title: {
                text: group.title,
                style: {
                  "fontSize": "14px",
                },
              },
              subtitle: {
                text: group.subtitle,
                html: true,
                style: {
                  "fontSize": "12px",
                },
              },
              chart: {
                marginRight: 35,
                marginLeft: 80,
                style: {
                  fontFamily: 'FuturaPT'
                },
                resetZoomButton: {
                  theme: {
                    display: 'none'
                  }
                },
                type: 'line',
                zoomType: 'xy',
                reflow: true,
                events: {
                    selection: (e) => {
                      if (!e.resetSelection) {
                        let xMin = e.xAxis[0].min;
                        let xMax = e.xAxis[0].max;
                        let yMin = e.yAxis[0].min;
                        let yMax = e.yAxis[0].max;
        
                        const key = "";
                        setExtremes(key, xMin, xMax, yMin, yMax);
                      }
                      e.preventDefault();
                    },
                    load: (e) => {
                      let chart = e.target;

                      if (!xMin && !xMax) {
                          chart.xAxis[0].setExtremes(
                              chart.xAxis[0].dataMin,
                              chart.xAxis[0].dataMax,
                              undefined,
                              false,
                              {trigger: 'load'}
                          );                                  
                      } else {
                          chart.xAxis[0].setExtremes(
                            xMin,
                            xMax,
                            undefined,
                            false,
                            {trigger: 'load'}
                          );
                      }
                  },
                },
              },
              legend: {
                enabled: false
              },
              series: group.chartSeriesList,
              tooltip: {
                formatter: function () {
                  const info = this.series.userOptions;
                  if (info) {
                    let isHistorical = info.isHistorical;
                    let timeLabelTimestamp = (isHistorical ? (this.x - chartData.historicalOffset) : this.x);
                    let timeLabel = Moment(timeLabelTimestamp).format('M/D/YYYY hh:mm:ss A');
                    if (info.alertSeries && this.point.spikeValue > 0) {
                      let confidenceScoreText = this.point.confidenceScore > 0 ? `, Confidence Score: ${Number(this.point.confidenceScore).toFixed(2)}` : '';
                      return `${this.series.name} : (${timeLabel}, </br> ${numeral(this.y).format("0,0.00")}, Anomaly Rank: ${Number(this.point.spikeValue).toFixed(2)}${confidenceScoreText})`;
                    }
                    return `${this.series.name} : (${timeLabel}, </br> ${numeral(this.y).format("0,0.00")} </br> )`;
                  } else {
                    let timeLabel = Moment(this.x).format('M/D/YYYY hh:mm:ss A');
                    return `${this.series.name} : (${timeLabel}, </br> ${numeral(this.y).format("0,0.00")} </br> )`;
                  }
                },
                xDateFormat: '%m/%d/%Y %H:%M:%S',
                shared: false,
                valueDecimals: 2,
                split: false
              },
            }
      
            if(chartConfig && isRegressionTrend ){
              let chartSeriesList = group.chartSeriesList || [];
              let regrationSeriesList = [];
              let obj = {}
              if(chartSeriesList) {
                chartSeriesList.forEach((element, i) => {
                  if(element.data.length >= 2) {
                    let data = null;
                    if (Object.isFrozen(element.data)) {
                      data = Array.from(element.data)
                    }
                    obj = {
                      data: data != null ? data : element.data,
                      regression: isRegressionTrend,
                      name: element.name,
                      regressionSettings: {
                        name: "Linear Regression - " + element.name,
                        type: "linear",
                      },
                      info: element.info
                    }
                  } else {
                    obj = {
                      data: element.data,
                      regression: false,
                      name: element.name,
                      regressionSettings: {
                        name: "Linear Regression" + element.name,
                        type: "linear",
                      },
                      info: element.info
                    }
                  }
                  regrationSeriesList.push(obj);
                });
              }
              chartConfig.series = regrationSeriesList;

            } else {
              chartConfig.series = group.chartSeriesList;
            }
            if(chartData.chartsResult.validationResultData) {
              if(chartData.chartsResult.validationResultData.chartBagSeries) {
                if(chartData.chartsResult.validationResultData.chartBagSeries[group.sectionId]) {
                  let isAddSeries = false;
                  let chartBagSeries = chartData.chartsResult.validationResultData.chartBagSeries[group.sectionId];
                  let uniqueChartBagSeries = [];
                  let seenSeries = new Set();
      
                  chartBagSeries.forEach(series => {
                    let uniqueKey = series.validationData.seriesName + "_" + series.templateName;
      
                    if (!seenSeries.has(uniqueKey)) {
                      seenSeries.add(uniqueKey);
      
                      let uniqueData = [];
                      let seenData = new Set();
      
                      series.validationData.data.forEach(entry => {
                        let dataKey = entry[0] + "_" + entry[1];
                        if (!seenData.has(dataKey)) {
                          seenData.add(dataKey);
                          uniqueData.push(entry);
                        }
                      });
      
                      // Assign unique data back
                      let newSeries = { ...series, validationData: { ...series.validationData, data: uniqueData } };
                      uniqueChartBagSeries.push(newSeries);
                    }
                  });
      
                  chartBagSeries = uniqueChartBagSeries;
      
                  for(let i = 0; i < chartBagSeries.length; i++) {
                    if(group.title.includes(chartBagSeries[i].validationData.seriesName)) {
                      if(group.templateName == chartBagSeries[i].templateName
                        && group.databaseName ? group.databaseName == chartBagSeries[i].validationData.databaseName : true) {
                          isAddSeries = true;
                      }
                    } else {
                      let name;
                      for(let j = 0; j < group.chartSeriesList.length; j++) {
                        name = group.chartSeriesList[j].name
                        if(name.includes(chartBagSeries[i].validationData.seriesName)) {
                          if(group.templateName == chartBagSeries[i].templateName
                            && group.databaseName ? group.databaseName == chartBagSeries[i].validationData.databaseName : true) {
                              isAddSeries = true;
                          }
                          break
                        }
                      }
                    }
                    if(isAddSeries && chartBagSeries[i].validationData.data.length > 0) {
                      let obj = {};
                      const formattedData =  chartBagSeries[i].validationData.data.map(([timestamp, y, value]) => ({
                        x: timestamp,
                        y: y,
                        spikeValue: value,
                        confidenceScore: 0,
                      }));
                      if(chartBagSeries[i].validationData.alertType.includes('Spike') || chartBagSeries[i].validationData.alertType.includes('Absolute Value Check')) {
                        obj = {
                          data: formattedData,
                          name: chartBagSeries[i].validationData.seriesName,
                          type: 'scatter',
                          alertSeries: true,
                          marker: {
                              fillColor: 'rgba(0,0,0,0)',
                              enabled: true,
                              radius: 12,
                              symbol: 'circle',
                              lineWidth: 3,
                              lineColor: '#FF0000'
                          },
                          showInLegend: false
                        }
                      }
                      if(obj && Object.keys(obj).length > 0) {
                        let newArray = [ ...chartConfig.series ];
                        newArray.push(obj)
                        chartConfig.series = newArray;
                      }
                    }
                  }
                }
              }
            }
            configList.push(chartConfig);
          });
        }
        return (
            isOpen && chartData ? <ChartsContainer configList={configList} handleClose={this.handleCancel} 
            templateName={chartData.chartsTemplate.templateName} handleResetZoom={this.handleResetZoom}
            handleLoadSelectedChart={handleLoadSelectedChart}/> : null
        );
    }
}

PEIChartTemplateContainer.propTypes = {
    //data
    dashletId: PropTypes.number,
    object: PropTypes.object,
  
    //func
    close: PropTypes.func,
    submit: PropTypes.func,
    generatePEIChart: PropTypes.func,
    handleClickOnPoint: PropTypes.func,
    setExtremes: PropTypes.func,
    handleLoadSelectedChart: PropTypes.func,
  };

const mapStateToProps = (state, props) => {
    return {
        isOpen: state.dashboard.isChartDialogOpen,
        chartData : state.dashboard.chartData,
        isRegressionTrend : state.dashboard.chartData ? state.dashboard.chartData.chartsTemplate.options.isRegressionTrend : null,
        xMin: state.dashboard.chartData?.xMin,
        xMax: state.dashboard.chartData?.xMax
        //   object: state.dashboard.dashboardTree.entities.dashlets[ownProps.dashletId].settings,
    }
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        generatePEIChart: (dashletId) => {
            dispatch(generatePEIChart(dashletId));
        },
        closeChartDialog: () => {
            dispatch(closeChartDialog());
        },
        handleClickOnPoint: (templateName) => {
            dispatch(handleClickOnPointPEI(templateName));
        },
        setExtremes: (key, xMin, xMax, yMin, yMax) => {
          dispatch(setExtremes(key, xMin, xMax, yMin, yMax))
        },
        handleLoadSelectedChart: () => {
          dispatch(handleLoadSelectedChartPEI());
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PEIChartTemplateContainer)