import React, {FC, useEffect, useState} from 'react';
import * as _ from 'lodash';
import HLCDashletsRestClient from 'api/rest/HLCDashletsRestClient';
import {useDispatch, useSelector} from 'react-redux';
import {selectReportNames, selectTemplateNames, selectTemplates, setDashletDataConfigProperty, setDashletOptionsProperty} from "reducers/dashboard/createDashletSlice";
import DashletConfigDropdown from 'components/dashboard/dashlets/DashletConfigDropdown/DashletConfigDropdown';
import HLCBackend from 'types/HLCBackend';
import {listReportNames, listTemplateNames} from "../actions";
import {
    HEATMAP_LIST_REPORT_NAMES_SUBSCRIPTION,
    HEATMAP_LIST_TEMPLATE_NAMES_SUBSCRIPTION
} from '../webSocketClient';
import {
    ListHeatmapReportNamesStompResponse,
    ListHeatmapTemplateNamesStompResponse
} from '../types';
import {backendAsOption, stringAsOption} from '../utils';
import DashletConfigSelectTargetNames from "../../components/DashletConfigSelectTargetNames";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import { loadHLCChartTemplates } from 'dashlets/HLCChartDashlet/actions';
import { LOAD_HLC_CHART_TEMPLATES_SUBSCRIPTION } from 'dashlets/HLCChartDashlet/webSocketClient';
import { selectIsLoadingReportNames, selectIsLoadingTemplateNames, selectIsLoadingTemplates, toggleIsLoadingReportNames, toggleIsLoadingTemplateNames, toggleIsLoadingTemplates } from 'reducers/dashboard/dashboardSlice';
import { FormLabel, Grid } from '@material-ui/core';
import TextField from 'components/common/TextField/TextField';
import Switch from "components/common/Switch/Switch";

interface HeatmapDashletCreateProps {
    dashletData: any;
    dashletErrors?: any;
}

const HeatmapDashletCreate: FC<HeatmapDashletCreateProps> = ({
                                             dashletErrors,
                                             dashletData
                                         }) => {
    const {
        backendKey,
        spikeDetectionReportName = null,
        staticTemplateName = null,
        targetNames,
        anomalyMagnitude = 1.5,
        useDefaultConfig = true
    } = dashletData.config;

    const [backends, setBackends] = useState<HLCBackend[]>([]);
    const [isLoadingBackends, setIsLoadingBackends] = useState<boolean>(false);
    // const [useDefaultConfig, setUseDefaultConfig] = useState<boolean>(false);
    // const [isLoadingTemplateNames, setIsLoadingTemplateNames] = useState<boolean>(false);
    // const [isLoadingReportNames, setIsLoadingReportNames] = useState<boolean>(false);
    // const [reportNames, setReportNames] = useState<string[]>([]);
    // const [templateNames, setTemplateNames] = useState<string[]>([]);
    // const [IsLoadingTemplates, setIsLoadingTemplates] = useState<boolean>(false);

    // const isLoadingTemplateNames = useSelector(selectIsLoadingTemplateNames);
    const isLoadingReportNames = useSelector(selectIsLoadingReportNames);
    const isLoadingTemplates = useSelector(selectIsLoadingTemplates);
    const templates = useSelector(selectTemplates)
    const reportNames = useSelector(selectReportNames);
    const dispatch = useDispatch();

    const isLoading = isLoadingBackends || isLoadingReportNames || isLoadingTemplates;

    const onChangeDashletConfigPropertyHandler = (event: React.ChangeEvent<{ name?: string; value: unknown; type?: string, valueAsNumber?: any }>) => {
        event.preventDefault();
        if (event.target.type === "number") {
            // @ts-ignore
            dispatch(setDashletDataConfigProperty(event.target.name, event.target.valueAsNumber));
        } else {
            // @ts-ignore
            dispatch(setDashletDataConfigProperty(event.target.name as string, event.target.value));
        }
    };

    const onSubmitTargetNames = (values: string[]) => {
        // @ts-ignore
        dispatch(setDashletDataConfigProperty('targetNames', values));
    }

    const toggleUseDefaultConfig = () => {
        // @ts-ignore
        dispatch(setDashletDataConfigProperty('useDefaultConfig', !useDefaultConfig));
    }

    // const handleListHeatmapReportNamesStompResponse = (response: ListHeatmapReportNamesStompResponse) => (dispatch: any, getState: any) => {
    //     if (!response.hasErrors) {
    //         dispatch(setDashletOptionsProperty({propertyName: "reportNames", propertyValue: response}))
    //         // setReportNames(response.reportNames);
    //     } else {
    //         console.log(response.errorMessage); //todo: handle error
    //     }
    //     dispatch(toggleIsLoadingReportNames(false));
    //     // setIsLoadingReportNames(false);
    // };

    // const handleListHeatmapTemplateNamesStompResponse = (response: ListHeatmapTemplateNamesStompResponse) => (dispatch: any, getState: any) => {
    //     if (!response.hasErrors) {
    //         setTemplateNames(response.templateNames);
    //     } else {
    //         console.log(response.errorMessage); //todo: handle error
    //     }
    //     dispatch(toggleIsLoadingTemplateNames(false));
    //     // setIsLoadingTemplateNames(false);
    // };

    useEffect(() => {
        if (!Object.prototype.hasOwnProperty.call(dashletData.config, 'useDefaultConfig')) {
            // @ts-ignore
            dispatch(setDashletDataConfigProperty('useDefaultConfig', useDefaultConfig));
            // @ts-ignore
            dispatch(setDashletDataConfigProperty('spikeDetectionReportName', spikeDetectionReportName));
            // @ts-ignore
            dispatch(setDashletDataConfigProperty('staticTemplateName', staticTemplateName));
        }
        if (!Object.prototype.hasOwnProperty.call(dashletData.config, 'anomalyMagnitude')) {
            // @ts-ignore
            dispatch(setDashletDataConfigProperty('anomalyMagnitude', anomalyMagnitude));
        }
    }, [dispatch]);

    // initial loading of the Backend list
    useEffect(() => {
        setIsLoadingBackends(true);
        HLCDashletsRestClient.listUserBackends()
            .then(backends => {
                setBackends(backends);
                setIsLoadingBackends(false);
            })
            .catch(error => {
                console.error(error); //todo: handle error
                setIsLoadingBackends(false);
            });
    }, []);

    useEffect(() => {
        if (backendKey) {
            // setIsLoadingTemplates(true);
            // dispatch(toggleIsLoadingTemplates(true))
            // dispatch(toggleIsLoadingTemplateNames(true))
            dispatch(loadHLCChartTemplates(backendKey));
        }
    }, [backendKey]);

    // list reports, templates and targets on change Backend
    useEffect(() => {
        if (backendKey) {
            // load report names
            // setIsLoadingReportNames(true);
            dispatch(toggleIsLoadingReportNames(true));
            dispatch(listReportNames(backendKey));

            // load template names
            // setIsLoadingTemplateNames(true);
            // dispatch(toggleIsLoadingTemplateNames(true))
            // dispatch(listTemplateNames(backendKey));
        }
    }, [backendKey]);

    const systemTemplates = templates
    ?.filter((option: any) => option.defaultDashboardTemplate)
    .map((option: any) => option.name) || [];

    const chartTemplates = templates
    ?.filter((option: any) => !option.defaultDashboardTemplate)
    .map((option: any) => option.name) || [];

    return <React.Fragment>
        <DashletConfigDropdown
            label="HLC Backend"
            name="backendKey"
            value={backendKey}
            options={backends.map((b: HLCBackend) => backendAsOption(b))}
            disabled={isLoadingBackends}
            error={dashletErrors?.backendKey}
            onChange={onChangeDashletConfigPropertyHandler}
        />
        <Grid item xs={12} style={{ display: 'flex', marginTop: '10px'}}>
            <FormLabel style={{width: "20rem", alignContent: "center"}}>
                Use Default Configuration
            </FormLabel>
            <Switch checked={useDefaultConfig}
                onChange={toggleUseDefaultConfig}
            />
        </Grid>
        <DashletConfigDropdown
            label="Spike Detection Report"
            name="spikeDetectionReportName"
            value={spikeDetectionReportName}
            options={
                _.map(reportNames, (obj: any) => {
                    return stringAsOption(obj.reportName, obj.alertsPresent);
            })}
            disabled={isLoading || useDefaultConfig}
            error={dashletErrors?.spikeDetectionReportName}
            onChange={onChangeDashletConfigPropertyHandler}
        />
        <DashletConfigDropdown
            label="Static Template"
            name="staticTemplateName"
            value={staticTemplateName}
            options={_.map(chartTemplates, (name: string) => stringAsOption(name))}
            options1={_.map(systemTemplates, (name: string) => stringAsOption(name))}
            disabled={isLoading || useDefaultConfig}
            error={dashletErrors?.staticTemplateName}
            onChange={onChangeDashletConfigPropertyHandler}
        />
        <Grid item xs={12} style={{ display: 'flex', marginTop: '10px'}}>
            <FormLabel style={{width: "20rem", alignContent: "center"}}>
                Anomaly Magnitude
            </FormLabel>
            <TextField
                name="anomalyMagnitude"
                onChange={onChangeDashletConfigPropertyHandler}
                value={anomalyMagnitude}
                inputProps={{
                    step: 1,
                    min: 10,
                    type: 'number',
                }}
            />
        </Grid>
        <DashletConfigSelectTargetNames
            multi
            backendKey={backendKey}
            targetNames={targetNames}
            onSubmitTargetNames={onSubmitTargetNames}
        />

        {/* <HLCUserSessionWebSocketSubscription
            subscriptionDestination={HEATMAP_LIST_REPORT_NAMES_SUBSCRIPTION}
            handleMessage={response => {
                if (!response.hasErrors) {
                    dispatch(setDashletOptionsProperty({propertyName: "reportNames", propertyValue: response.reportNames}))
                    // setReportNames(response.reportNames);
                } else {
                    console.log(response.errorMessage); //todo: handle error
                }
                dispatch(toggleIsLoadingReportNames(false));
            }}
        /> */}
        {/* <HLCUserSessionWebSocketSubscription
            subscriptionDestination={HEATMAP_LIST_TEMPLATE_NAMES_SUBSCRIPTION}
            handleMessage={handleListHeatmapTemplateNamesStompResponse}
        /> */}

        {/* <HLCUserSessionWebSocketSubscription
            subscriptionDestination={LOAD_HLC_CHART_TEMPLATES_SUBSCRIPTION}
            handleMessage={response => {
                const {templates} = response;
                dispatch(setDashletOptionsProperty({propertyName: "templates", propertyValue: templates}));
                // dispatch(toggleIsLoadingTemplateNames(false))
                dispatch(toggleIsLoadingTemplates(false))
                // setIsLoadingTemplates(false);
            }}
        /> */}

    </React.Fragment>;
};

export default HeatmapDashletCreate;