import DemoTargetsGenerationParams from "types/model/infrastructure/simulation/DemoTargetsGenerationParams";

const createDemoTargetGenerationParams = (defaults: Partial<DemoTargetsGenerationParams> = {}): DemoTargetsGenerationParams => {
    return {
        demoTargetsParams: defaults.demoTargetsParams || [],
        targetNamePrefix: defaults.targetNamePrefix || "DEMO_",
        targetSQLCount: defaults.targetSQLCount || 100,
        timeAmount: defaults.timeAmount || 2,
        timeAmountUnit: defaults.timeAmountUnit || "Day",
        frequencySeconds: defaults.frequencySeconds || 5
    }
};

export default createDemoTargetGenerationParams;
