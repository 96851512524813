import {
  addDashletOnDashboard, createDashboard, createDashboardGroup,
  editDashboard,
  editDashboardGroup, refreshActiveDashboard, removeDashboard, removeDashboardGroup,
} from "actions/dashboardActions";
import DashboardLeftNavBar from "components/dashboard/DashboardLeftNavBar/DashboardLeftNavBar";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {activeDashboardIdSelector, dashboardTreeSelector} from "selectors/dashboard/DashboardSelectors";
import RemoveConfirmation from "views/dashboard/Home/RemoveConfirmation";
import {openNewDashboard, setActiveDashboardId, toggleDashboardGroup} from "../../reducers/dashboard/dashboardSlice";
import { onImportDashlet } from "reducers/dashboard/createDashletSlice";
import { DASHBOARD_URL } from "config";
import { selectIsStompClientConnected } from "reducers/webSocketSlice";


class DashboardLeftNavBarContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      removeEntityId: null,
      removeEntityName: null,
      isRemoveDashboardGroupConfirmationOpened: false,
      isRemoveDashboardConfirmationOpened: false,
      isDashboardModalOpened: false,
      dashboardData: null,
    }
  };

  componentDidUpdate() {
    if (this.props.dashboardTree && Object.keys(this.props.dashboardTree.entities.dashboardGroups).length > 0 && this.props.isHLCStompClientConnected) {
      const params = new URLSearchParams(window.location.search);
      if (params.get('dashboardId')) {
        let dashboardId = parseInt(params.get('dashboardId'), 10);
        if (this.props.setActiveDashboardId) {
          this.props.setActiveDashboardId(dashboardId);
        }
      }
      
      if (params.get('dashboardGroupId')) {
        let dashboardGroupId = parseInt(params.get('dashboardGroupId'), 10);
        if (this.props.toggleDashboardGroup) {
          this.props.toggleDashboardGroup(dashboardGroupId);
        }
      }
      window.history.replaceState({}, document.title, DASHBOARD_URL);
    }
  }

  onSubmitCreateDashboardGroupHandler = (name) => {
    const dashboardGroup = {
      name: name,
      description: null
    };
    this.props.createDashboardGroup(dashboardGroup)
  };

  onCreateDashboardHandler = (dashboardGroupId) => {
    this.props.newDashboard(dashboardGroupId);
  };

  openDashboardModal = (dashboardData) => {
    this.setState({
      dashboardData: dashboardData,
      isDashboardModalOpened: true
    });
  };

  closeDashboardModal = () => {
    this.setState({
      dashboardData: null,
      isDashboardModalOpened: false
    });
  };

  onSubmitCreateDashboardHandler = (dashboard) => {
    this.closeDashboardModal();
    this.props.createDashboard(dashboard);
  };

  onRemoveDashboardGroupHandler = (dashboardGroupId, dashboardGroupName) => {
    this.openRemoveDashboardGroupConfirmation(dashboardGroupId, dashboardGroupName);
  };

  onRemoveDashboardHandler = (dashboardId, dashboardName) => {
    this.openRemoveDashboardConfirmation(dashboardId, dashboardName);
  };

  openRemoveDashboardGroupConfirmation = (dashboardGroupId, dashboardGroupName) => {
    this.setState({
      removeEntityId: dashboardGroupId,
      removeEntityName: dashboardGroupName,
      isRemoveDashboardGroupConfirmationOpened: true
    });
  };

  openRemoveDashboardConfirmation = (dashboardId, dashboardName) => {
    this.setState({
      removeEntityId: dashboardId,
      removeEntityName: dashboardName,
      isRemoveDashboardConfirmationOpened: true
    });
  };

  closeRemoveDashboardGroupConfirmation = () => {
    this.setState({
      removeEntityId: null,
      removeEntityName: null,
      isRemoveDashboardGroupConfirmationOpened: false
    })
  };

  closeRemoveDashboardConfirmation = () => {
    this.setState({
      removeEntityId: null,
      removeEntityName: null,
      isRemoveDashboardConfirmationOpened: false
    })
  };

  onCancelRemoveDashboardGroupHandler = () => {
    this.closeRemoveDashboardGroupConfirmation();
  };

  onCancelRemoveDashboardHandler = () => {
    this.closeRemoveDashboardConfirmation();
  };

  onConfirmRemoveDashboardGroupHandler = () => {
    this.props.removeDashboardGroup(this.state.removeEntityId);
    this.closeRemoveDashboardGroupConfirmation();
  };

  onConfirmRemoveDashboardHandler = () => {
    this.props.removeDashboard(this.state.removeEntityId);
    this.closeRemoveDashboardConfirmation();
  };

  render() {
    const {isLeftMenuOpened, setLeftMenuOpened, editDashboardGroup, dashboardTree, editDashboard, onAddDashletHandler, onRefreshDashboardHandler, onImportDashlet} = this.props;
    const {isRemoveDashboardGroupConfirmationOpened, removeEntityName, isRemoveDashboardConfirmationOpened } = this.state;

    return [
      <DashboardLeftNavBar
          {...this.props}
          key="dash-tree"
          dashboardTree={dashboardTree}
          onSubmitCreateDashboardGroupHandler={this.onSubmitCreateDashboardGroupHandler}
          onRemoveDashboardGroupHandler={this.onRemoveDashboardGroupHandler}
          onRemoveDashboardHandler={this.onRemoveDashboardHandler}
          onCreateDashboardHandler={this.onCreateDashboardHandler}
          onEditDashboardGroupHandler={editDashboardGroup}
          onEditDashboardHandler={editDashboard}
          onAddDashletHandler={onAddDashletHandler}
          onImportDashlet={onImportDashlet}
          onRefreshDashboardHandler={onRefreshDashboardHandler}
          isLeftMenuOpened={isLeftMenuOpened}
          setLeftMenuOpened={setLeftMenuOpened}
      />,
      <RemoveConfirmation
          key="dash-remove-dash-grp-confirm-modal"
          open={isRemoveDashboardGroupConfirmationOpened}
          title="Remove Dashboard Group"
          message={`Please confirm removing of Dashboard Group ${removeEntityName}. All Dashboards in the Group will be removed!`}
          handleClose={this.onCancelRemoveDashboardGroupHandler}
          handleConfirm={this.onConfirmRemoveDashboardGroupHandler}
      />,
      <RemoveConfirmation
          key="dash-remove-dash-confirm-modal"
          open={isRemoveDashboardConfirmationOpened}
          title="Remove Dashboard"
          message={`Please confirm removing of Dashboard ${removeEntityName}.`}
          handleClose={this.onCancelRemoveDashboardHandler}
          handleConfirm={this.onConfirmRemoveDashboardHandler}
      />,
    ];
  }
}

DashboardLeftNavBarContainer.propTypes = {
  //data
  isLeftMenuOpened: PropTypes.bool,
  dashboardTree: PropTypes.object.isRequired,
  activeDashboardId: PropTypes.number,
  isHLCStompClientConnected: PropTypes.bool,

  //func
  setLeftMenuOpened: PropTypes.func,
  createDashboardGroup: PropTypes.func.isRequired,
  removeDashboardGroup: PropTypes.func.isRequired,
  setActiveDashboardId: PropTypes.func.isRequired,
  toggleDashboardGroup: PropTypes.func.isRequired,
  removeDashboard: PropTypes.func.isRequired,
  createDashboard: PropTypes.func.isRequired,
  editDashboardGroup: PropTypes.func.isRequired,
  editDashboard: PropTypes.func.isRequired,
  onAddDashletHandler: PropTypes.func.isRequired,
  onImportDashlet: PropTypes.func.isRequired,
  onRefreshDashboardHandler: PropTypes.func.isRequired,
  newDashboard: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    dashboardTree: dashboardTreeSelector(state),
    activeDashboardId: activeDashboardIdSelector(state),
    isHLCStompClientConnected: selectIsStompClientConnected(state),
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    createDashboardGroup: (dashboardGroup) => {
      dispatch(createDashboardGroup(dashboardGroup));
    },
    removeDashboardGroup: (dashboardGroupId) => {
      dispatch(removeDashboardGroup(dashboardGroupId));
    },
    setActiveDashboardId: (dashboardId) => {
      dispatch(setActiveDashboardId(dashboardId));
    },
    toggleDashboardGroup: (dashboardGroupId) => {
      dispatch(toggleDashboardGroup(dashboardGroupId));
    },
    removeDashboard: (dashboardId) => {
      dispatch(removeDashboard(dashboardId));
    },
    createDashboard: (dashboard) => {
      dispatch(createDashboard(dashboard));
    },
    editDashboardGroup: (dashboardGroupId) => {
      dispatch(editDashboardGroup(dashboardGroupId));
    },
    editDashboard: (dashboardId) => {
      dispatch(editDashboard(dashboardId));
    },
    onAddDashletHandler: (dashboardId) => {
      dispatch(addDashletOnDashboard(dashboardId));
    },
    onRefreshDashboardHandler: () => {
      dispatch(refreshActiveDashboard());
    },
    newDashboard: (dashboardGroupId) => {
      dispatch(openNewDashboard(dashboardGroupId));
    },
    onImportDashlet: (dashboardId) => {
      dispatch(onImportDashlet(dashboardId));
    }
  }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardLeftNavBarContainer)
