import {
  cancelImportDashlet,
} from "actions/dashboardActions";
import ImportDashletModal from "components/dashboard/ImportDashletModal/ImportDashletModal";
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as _ from "lodash";
import { dashboardModalSelector } from "selectors/dashboard/DashboardSelectors";
import { setDashboardModalProperty } from "../../reducers/dashboard/dashboardSlice";
import { importDashlet } from "reducers/dashboard/createDashletSlice";

class ImportDashletModalContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLocked: false,
      errors: {}
    };
  }


  lockForm = () => {
    this.setState({ isLocked: true });
  };

  unlockForm = () => {
    this.setState({ isLocked: false });
  };

  onClickCancelHandler = (event) => {
    event.preventDefault();
    this.props.cancel();
  };

  onChangeInputHandler = (event) => {
    event.preventDefault();

    let { errors } = this.state;
    const target = event.target;

    this.props.setDashboardModalProperty(target.name, target.value);
    errors[target.name] = false;

    this.setState({ errors });
  };

  validateObjectData = (objectData) => {
    const errors = {};

    if (!_.trim(objectData.name)) {
      errors.name = true;
    }

    return errors;
  };

  validate = () => {
    const { objectData } = this.props;
    const errors = this.validateObjectData(objectData);
    this.setState({ errors });

    return _.keys(errors).length > 0;
  };

  render() {
    const { errors, isLocked } = this.state;
    const {objectData} = this.props;
    const enabled = Boolean(objectData);

    return this.props ? (
      <ImportDashletModal
        open={this.props.isOpenImportDashletModal}
        objectData={objectData}
        errors={errors}
        isLocked={isLocked}
        handleChangeInput={this.onChangeInputHandler}
        handleClose={this.onClickCancelHandler}
        {...this.props}
      />
    ) : null;
  }
}

ImportDashletModalContainer.propTypes = {
  //data
  objectData: PropTypes.object,

  //func
  cancel: PropTypes.func,
  setDashboardModalProperty: PropTypes.func,
  handleImportDashlet: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    objectData: dashboardModalSelector(state),
    isOpenImportDashletModal: state.createDashlet.isOpenImportDashletModal
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancel: () => {
      dispatch(cancelImportDashlet());
    },
    handleImportDashlet: (file, selectedBackendKey, selectedBaceknd) => {
      dispatch(importDashlet(file, selectedBackendKey, selectedBaceknd));
    },
    setDashboardModalProperty: (propertyName, propertyValue) => {
      dispatch(setDashboardModalProperty(propertyName, propertyValue));
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportDashletModalContainer)