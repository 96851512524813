import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Badge, Menu, MenuItem, Divider, Typography, Card, CircularProgress } from "@material-ui/core";
import { NotificationsActive, NotificationsNone } from "@material-ui/icons";
import { markAsRead, selectNotificationsCounts, selectNotificationsEntities, selectNotificationsIds, selectNotificationsLoading } from "reducers/notificationsSlice";
import { fetchNotifications, markAsReadNotification, totalNotifications } from "actions/NotificationAction";
import "./Notification.css";

const Notification = ({ userId }) => {
  const dispatch = useDispatch();
  const notificationsEntities = useSelector(selectNotificationsEntities);
  const notificationIds = useSelector(selectNotificationsIds);
  const notificationCount = useSelector(selectNotificationsCounts);
  const isLoading = useSelector(selectNotificationsLoading); 

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    dispatch(totalNotifications());
  }, []);

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch(fetchNotifications());
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAsRead = (id) => {
    dispatch(markAsReadNotification(id));
    dispatch(markAsRead(id));
  };

  // Get unread notifications
  const unreadNotifications = notificationIds
    .map((id) => notificationsEntities[id])
    .filter((notification) => !notification.isRead);

  // Get read notifications
  const readNotifications = notificationIds
    .map((id) => notificationsEntities[id])
    .filter((notification) => notification.isRead);


  return (
    <>
      {/* Notification Icon with Badge */}
      <IconButton
        color="inherit"
        onClick={handleNotificationClick}
        style={{
          // position: "absolute",
          // right: "8rem",
          // top: "4px",
        }}
      >
        <Badge badgeContent={notificationCount.totalView > 0 ? 0 : notificationCount.unread} color="error">
          {notificationCount.unread > 0 ? (
            <NotificationsActive style={{ color: "#0F6CBD" }} />
          ) : (
            <NotificationsNone style={{ color: "black" }} />
          )}
        </Badge>
      </IconButton>

      {/* Notification Dropdown */}
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        style={{ marginTop: "32px" }}
        PaperProps={{
          style: {
            maxHeight: "450px",
            width: "380px",
            borderRadius: "10px",
            boxShadow: "0 6px 14px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <div className="notification-scroll" style={{ maxHeight: "400px", overflowY: "auto", padding: "10px" }}>
        {isLoading ? (
            <div style={{ textAlign: "center", padding: "20px" }}>
              <CircularProgress />
            </div>
          ) :
          notificationIds.length > 0 ? (
            <>
              {/* Unread Notifications */}
              {unreadNotifications.map((notification) => (
                <Card
                  key={notification.id}
                  onClick={() => handleMarkAsRead(notification.id)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                    border: "1px solid rgb(117 173 221)",
                    borderRadius: "8px",
                    padding: "12px",
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.1)",
                    marginBottom: "6px",
                  }}
                  className="notification-item"
                >
                  <Typography variant="body1" style={{
                    fontWeight: "bold",
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    flex: 1,
                    textAlign: "justify",
                  }}>
                    {notification.title}
                  </Typography>
                  <span
                    style={{
                      backgroundColor: "#0078D4",
                      color: "white",
                      fontSize: "10px",
                      fontWeight: "bold",
                      borderRadius: "15px",
                      textAlign: "center",
                      animation: "pulse 1.5s infinite",
                      marginLeft: "auto",
                      height: "24px",
                      lineHeight: "16px",
                      minWidth: "40px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    NEW
                  </span>
                </Card>
              ))}

              {/* Divider with Label */}
              {unreadNotifications.length > 0 && readNotifications.length > 0 && (
                <>
                  <Divider style={{ margin: "12px 0" }} />
                  <Typography variant="caption" style={{ textAlign: "center", display: "block", color: "#757575" }}>
                    Read Notifications
                  </Typography>
                  <Divider style={{ margin: "6px 0" }} />
                </>
              )}

              {/* Read Notifications */}
              {readNotifications.map((notification) => (
                <Card
                  key={notification.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                    background: "white",
                    borderRadius: "8px",
                    padding: "10px",
                    transition: "all 0.3s ease",
                    boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.08)",
                    marginBottom: "6px",
                  }}
                  className="notification-item"
                >
                  <Typography variant="body2">{notification.title}</Typography>
                </Card>
              ))}
            </>
          ) : (
            <MenuItem onClick={handleMenuClose}>No new notifications</MenuItem>
          )}
        </div>
      </Menu>

      {/* Animation Styles */}
      <style>
        {`
        @keyframes pulse {
          0% { transform: scale(1); opacity: 1; }
          50% { transform: scale(1.1); opacity: 0.8; }
          100% { transform: scale(1); opacity: 1; }
        }

        .notification-item:hover {
          // transform: translateY(-2px);
          box-shadow: 3px 6px 12px rgba(0, 0, 0, 0.15);
        }
        `}
      </style>
    </>
  );
};

export default Notification;


// Second Notification ui

// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { IconButton, Badge, Menu, MenuItem } from "@material-ui/core";
// import { Alert20Filled } from "@fluentui/react-icons";
// import { markAsRead, selectNotificationsEntities, selectNotificationsIds } from "reducers/notificationsSlice";
// import { fetchNotifications, markAsReadNotification } from "actions/NotificationAction";
// import "./Notification.css";

// const Notification = ({ userId }) => {
//   const dispatch = useDispatch();
//   const notificationsEntities = useSelector(selectNotificationsEntities);
//   const notificationIds = useSelector(selectNotificationsIds);

//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const isMenuOpen = Boolean(anchorEl);

//   useEffect(() => {
//     // dispatch(fetchNotifications());
//   }, []);

//   const handleNotificationClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const handleMarkAsRead = (id) => {
//     dispatch(markAsReadNotification(id));
//     dispatch(markAsRead(id));
//   };

//   // Get unread notifications count
//   const unreadCount = notificationIds.filter(id => !notificationsEntities[id]?.isRead).length;

//   return (
//     <>
//       {/* Notification Icon with Badge */}
//       <IconButton 
//         color="inherit" 
//         onClick={handleNotificationClick}
//         style={{
//           width: '50px',
//           position: 'absolute',
//           right: '8rem',
//           top: '4px',
//         }}
//       >        
//         <Badge badgeContent={unreadCount} color="error">
//           <Alert20Filled style={{ color: 'black' }} />
//         </Badge>
//       </IconButton>

//       {/* Notification Dropdown */}
//       <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose} style={{marginTop: '32px'}}
//         PaperProps={{
//           style: {
//             maxHeight: "400px",
//             width: "400px",
//             padding: 0,
//             borderRadius: "8px",
//             boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
//             // overflowY: "auto",
//           }
//         }}
//       >
//         <div style={{ maxHeight: "350px", overflowY: "auto", padding: "5px" }}>
//           {notificationIds.length > 0 ? (
//             notificationIds.map((id) => {
//               const notification = notificationsEntities[id];
//               return (
//                 <MenuItem 
//                   key={id} 
//                   onClick={() => handleMarkAsRead(id)}
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "space-between",
//                     gap: "10px",
//                     background: notification.isRead ? "white" : "#E3F2FD",
//                     borderRadius: "5px",
//                     padding: "10px",
//                     wordBreak: "break-word",
//                     whiteSpace: "normal",
//                     marginBottom: "3px",
//                     fontWeight: notification.isRead ? "normal" : "bold",
//                   }}
//                 >
//                   {notification.title}
//                   <span
//                     style={{
//                       backgroundColor: "#0078D4",
//                       color: "white",
//                       fontSize: "10px",
//                       fontWeight: "bold",
//                       padding: "2px 6px",
//                       borderRadius: "12px",
//                       minWidth: "30px",
//                       textAlign: "center",
//                       visibility: notification.isRead ? "hidden" : "visible",
//                       animation: notification.isRead ? "none" : "pulse 1.5s infinite",
//                     }}
//                   >
//                     NEW
//                   </span>
//                 </MenuItem>
//               );
//             })
//           ) : (
//             <MenuItem onClick={handleMenuClose}>No new notifications</MenuItem>
//           )}
//         </div>
//       </Menu>
//     </>
//   );
// };

// export default Notification;
