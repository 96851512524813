import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {listReportNames, listTemplateNames} from "../actions";
import DashletConfigDropdown from "components/dashboard/dashlets/DashletConfigDropdown/DashletConfigDropdown";
import * as _ from "lodash";
import {stringAsOption} from "../utils";
import {
    ListHeatmapReportNamesStompResponse,
    ListHeatmapTemplateNamesStompResponse
} from "../types";
import {
    HEATMAP_LIST_REPORT_NAMES_SUBSCRIPTION,
    HEATMAP_LIST_TEMPLATE_NAMES_SUBSCRIPTION
} from "../webSocketClient";
import DashletConfigSelectTargetNames from "../../components/DashletConfigSelectTargetNames";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import { selectIsLoadingReportNames, selectIsLoadingTemplateNames, toggleIsLoadingReportNames, toggleIsLoadingTemplateNames } from 'reducers/dashboard/dashboardSlice';
import {
    selectReportNames,
    selectTemplateName,
    selectTemplates,
} from 'reducers/dashboard/createDashletSlice';
import { FormLabel, Grid } from '@material-ui/core';
import TextField from 'components/common/TextField/TextField';
import Switch from "../../../components/common/Switch/Switch";
import { loadHLCChartTemplates } from 'dashlets/HLCChartDashlet/actions';

interface HeatmapDashletEditProps {
    dashletConfig: any;
    setDashletConfigProperty: (propertyName: string, propertyValue: unknown) => void;
}

const HeatmapDashletEdit: React.FC<HeatmapDashletEditProps> = ({
                                                                               dashletConfig,
                                                                               setDashletConfigProperty
                                                                           }) => {
    const {backendKey} = dashletConfig;
    // const [isLoadingTemplateNames, setIsLoadingTemplateNames] = useState<boolean>(false);
    // const [isLoadingReportNames, setIsLoadingReportNames] = useState<boolean>(false);
    // const [reportNames, setReportNames] = useState<string[]>([]);
    // const [templateNames, setTemplateNames] = useState<string[]>([]);
    const dispatch = useDispatch();

    const isLoadingTemplateNames = useSelector(selectIsLoadingTemplateNames);
    const isLoadingReportNames = useSelector(selectIsLoadingReportNames);
    const templates = useSelector(selectTemplates)
    const reportNames = useSelector(selectReportNames);

    const isLoading = isLoadingReportNames || isLoadingTemplateNames;

    const onChangeDashletConfigPropertyHandler = (event: React.ChangeEvent<{ name?: string; value: unknown; type?: string, valueAsNumber?: any}>) => {
        event.preventDefault();
        const name = event.target.name;
        if (name) {
            if (event.target.type === "number") {
                setDashletConfigProperty(event.target.name as string, event.target.valueAsNumber);
            } else {
                setDashletConfigProperty(event.target.name as string, event.target.value);
            }
        }
    };

    const toggleUseDefaultConfig = () => {
        // @ts-ignore
        setDashletConfigProperty('useDefaultConfig', !dashletConfig.useDefaultConfig);
    }

    const onSubmitTargetNames = (values: string[]) => setDashletConfigProperty('targetNames', values);

    // const handleListHeatmapReportNamesStompResponse = (response: ListHeatmapReportNamesStompResponse) => {
    //     if (!response.hasErrors) {
    //         // setReportNames(response.reportNames);
    //         dispatch(setDashletOptionsProperty({propertyName: "reportNames", propertyValue: response.reportNames}))
    //     } else {
    //         console.log(response.errorMessage); //todo: handle error
    //     }
    //     dispatch(toggleIsLoadingReportNames(false));
    //     // setIsLoadingReportNames(false);
    // };

    // const handleListHeatmapTemplateNamesStompResponse = (response: ListHeatmapTemplateNamesStompResponse) => {
    //     if (!response.hasErrors) {
    //         dispatch(setDashletOptionsProperty({propertyName: "templates", propertyValue: response.templateNames}))
    //         // setTemplateNames(response.templateNames);
    //     } else {
    //         console.log(response.errorMessage); //todo: handle error
    //     }
    //     dispatch(toggleIsLoadingTemplateNames(false))
    //     // setIsLoadingTemplateNames(false);
    // };

    // list reports, templates and targets on change Backend
    useEffect(() => {
        if (backendKey) {
            // load report names
            dispatch(toggleIsLoadingReportNames(true))
            dispatch(listReportNames(backendKey));
            // setIsLoadingReportNames(true);
            // dispatch(listReportNames(backendKey));

            // // load template names
            // setIsLoadingTemplateNames(true);
            dispatch(toggleIsLoadingTemplateNames(true))
            // dispatch(listTemplateNames(backendKey));
        }
    }, [backendKey]);

    useEffect(() => {
        if (backendKey) {
            // setIsLoadingTemplates(true);
            // dispatch(toggleIsLoadingTemplates(true))
            // dispatch(toggleIsLoadingTemplateNames(true))
            dispatch(loadHLCChartTemplates(backendKey));
        }
    }, [backendKey]);
    
    const systemTemplates = templates
    ?.filter((option: any) => option.defaultDashboardTemplate)
    .map((option: any) => option.name) || [];

    const chartTemplates = templates
    ?.filter((option: any) => !option.defaultDashboardTemplate)
    .map((option: any) => option.name) || [];

    return <React.Fragment>
        <Grid item xs={12} style={{ display: 'flex', marginTop: '10px'}}>
            <FormLabel style={{width: "20rem", alignContent: "center"}}>
                Use Default Configuration
            </FormLabel>
            <Switch checked={dashletConfig.useDefaultConfig}
                    onChange={toggleUseDefaultConfig}
            />
        </Grid>
        <DashletConfigDropdown
            label="Spike Detection Report"
            name="spikeDetectionReportName"
            value={dashletConfig.spikeDetectionReportName}
            options={
                _.map(reportNames, (obj: any) => {
                    return stringAsOption(obj.reportName, obj.alertsPresent);
            })}
            disabled={isLoading || dashletConfig.useDefaultConfig}
            // error={dashletErrors?.spikeDetectionReportName}
            onChange={onChangeDashletConfigPropertyHandler}
        />
        <DashletConfigDropdown
            label="Static Template"
            name="staticTemplateName"
            value={dashletConfig.staticTemplateName}
            options={_.map(chartTemplates, (name: string) => stringAsOption(name))}
            options1={_.map(systemTemplates, (name: string) => stringAsOption(name))}
            disabled={isLoading || dashletConfig.useDefaultConfig}
            // error={dashletErrors?.staticTemplateName}
            onChange={onChangeDashletConfigPropertyHandler}
        />
       <Grid item xs={12} style={{ display: 'flex', marginTop: '10px'}}>
            <FormLabel style={{width: "20rem", alignContent: "center"}}>
                Anomaly Magnitude
            </FormLabel>
            <TextField
                name="anomalyMagnitude"
                onChange={onChangeDashletConfigPropertyHandler}
                value={dashletConfig.anomalyMagnitude}
                inputProps={{
                    step: 1,
                    min: 10,
                    type: 'number',
                }}
            />
        </Grid>
        <DashletConfigSelectTargetNames
            multi
            backendKey={backendKey}
            targetNames={dashletConfig.targetNames}
            onSubmitTargetNames={onSubmitTargetNames}
        />

        {/* <HLCUserSessionWebSocketSubscription
            subscriptionDestination={HEATMAP_LIST_REPORT_NAMES_SUBSCRIPTION}
            handleMessage={handleListHeatmapReportNamesStompResponse}
        /> */}
        {/* <HLCUserSessionWebSocketSubscription
            subscriptionDestination={HEATMAP_LIST_TEMPLATE_NAMES_SUBSCRIPTION}
            handleMessage={response => {
                if (!response.hasErrors) {
                    dispatch(setDashletOptionsProperty({propertyName: "templates", propertyValue: response.templateNames}))
                    // setTemplateNames(response.templateNames);
                } else {
                    console.log(response.errorMessage); //todo: handle error
                }
                dispatch(toggleIsLoadingTemplateNames(false))
                // setIsLoadingTemplateNames(false);
            }}
        /> */}
    </React.Fragment>;
};

export default HeatmapDashletEdit;

