import {WEB_SOCKET_ACTION_PREFIX, WEB_SOCKET_SUBSCR_PREFIX} from "constants/webSocketConstants";
import {publish} from "./hlcCommonWebSocketClient";
import ExecuteDemoTargetsGenerationStompFrame
    from "types/payload/infrastructure/simulation/ExecuteDemoTargetsGenerationStompFrame";
import DeleteDemoTargetsSimulationRequestPayload
    from "types/payload/infrastructure/simulation/DeleteDemoTargetsSimulationRequestPayload";

export const generateDemoTargets = (appState: any, backendKey: string, payload: ExecuteDemoTargetsGenerationStompFrame) =>
    publish(
        appState,
        backendKey,
        `${WEB_SOCKET_ACTION_PREFIX}/ui.infrastructure.simulation.targets.generate.execute`,
        payload
    );

export const deleteDemoTargets = (appState: any, backendKey: string, targetNamePrefix: string | null = null) =>
    publish(
        appState,
        backendKey,
        `${WEB_SOCKET_ACTION_PREFIX}/ui.infrastructure.simulation.targets.delete.execute`,
        {
            targetNamePrefix
        } as DeleteDemoTargetsSimulationRequestPayload
    );

export const SIMULATION_OPERATION_SUBSCRIPTION
    = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.infrastructure.simulation.operation.results`;

