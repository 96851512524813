import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectBackendsLogsBackendKey,
    selectBackendsLogsLines,
    setBackendsLogsBackendKey,
} from "reducers/infrastructure/backendsLogsSlice";
import {handleLoadLogs, loadLogs} from "actions/infrastructure/backendsLogsActions";
import {Box, Tab, Tabs} from "@material-ui/core";
import BackendSelectionRow from "components/common/BackendSelectionRow";
import {HLC_WEB_SOCKET_URL, IS_HLC_WS_ENABLED, IS_PEI_WS_ENABLED, PEI_WEB_SOCKET_URL} from "config";
import StompClient from "websocket/StompClient";
import {selectStompClient, STOMP_CLIENT} from "reducers/webSocketSlice";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import {LOGS_SUBSCRIPTION} from "api/websocket/hlc/backendsLogsWebSocketClient";
import LogsView from "./LogsView";
import PEIChartBagWebSocketSubscription from "dashlets/PEIChartBagDashlet/components/PEIChartBagWebSocketSubscription";
import WebSocketSubscription from "websocket/WebSocketSubscription";

const Logs: React.FC = ({}) => {
    const dispatch = useDispatch();
    const backendKey = useSelector(selectBackendsLogsBackendKey);
    const stompClient = useSelector(selectStompClient);
    
    const logLines = useSelector(selectBackendsLogsLines);
    const [loadPEILogs, setLoadPEILogs] = React.useState<boolean>(false);
    const [selectedTab, setSelectedTab] = React.useState<number>(0);
    const [loadPEIBacked, setLoadPEIBacked] = React.useState<boolean>(false);
    

    const handleChangeBackend = (backendKey: string) => {
        setLoadPEILogs(loadPEIBacked);
        dispatch(setBackendsLogsBackendKey(backendKey));
    }

    const handleTabChange = (event: any, newValue: number) => {
        setSelectedTab(newValue);
        setLoadPEIBacked(newValue == 1);
        dispatch(setBackendsLogsBackendKey(""));
    };

    const load = (loadPEILogs: boolean) => backendKey && dispatch(loadLogs(backendKey, loadPEILogs));

    useEffect(() => {
        load(loadPEILogs);
    }, [backendKey, selectedTab]);

    return (
        <>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label="HLC Logs" value={0} />
                <Tab label="PEI Logs" value={1} />
            </Tabs>
            <Box style={{
                display: 'block',
                height: "100%",
                width: "100%",
                overflowY: "scroll",
                overflowX: "hidden"
            }}>
                <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: "100%",
                    height: "100%"
                }}
                >
                    <BackendSelectionRow backendKey={backendKey} onChangeBackend={handleChangeBackend} selectedTabLogs={selectedTab}/>

                    <LogsView lines={logLines} />
                </Box>
            </Box>

            {/* todo: move to common place */}
            {/* {IS_PEI_WS_ENABLED ? [
                <StompClient
                    key="hlc-ws-stomp-client-1582840873831"
                    stompClientKey={STOMP_CLIENT.PEI}
                    sockJsEndpoint={PEI_WEB_SOCKET_URL}
                    debugMode
                />,
            ] : null} */}
            {IS_HLC_WS_ENABLED ? [
                <StompClient
                    key="pei-ws-stomp-client-1582840873831"
                    stompClientKey={STOMP_CLIENT.HLC}
                    sockJsEndpoint={HLC_WEB_SOCKET_URL}
                    debugMode
                />,
            ] : null}

            {backendKey && loadPEILogs && <PEIChartBagWebSocketSubscription />}
            {backendKey && !loadPEILogs && <WebSocketSubscription
                        stompClient={stompClient}
                        backendKey={backendKey}
                        subscriptionDestination={LOGS_SUBSCRIPTION}
                        handleMessage={response => dispatch(handleLoadLogs(response))}
            />}

        </>

    );
};

export default Logs;
