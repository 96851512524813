import {BASE_API_URL} from "config";
import CommonRestClient from "api/rest/CommonRestClient";

class NotificationRestClient extends CommonRestClient {

    async notificationCount() {
        return await this.get("/count");
    }

    async allNotificationStatus() {
        return await this.get("/allNotificationStatus");
    }

    async markNotificationAsRead(id) {
        return await this.put(`/markAsRead/${id}`);
    }
}

export default new NotificationRestClient(`${BASE_API_URL}/notifications`);