import {useSelector} from "react-redux";
import {selectDashletData} from "../../reducers/dashboard/dashboardSlice";
import {selectStompClient, selectIsStompClientConnected} from "../../reducers/webSocketSlice";
import {getUserSessionId} from "../../actions/userInfoActions";

function useCommonDashletProperties(dashlet: any) {
    const dashletId = dashlet.id;
    const data = useSelector(state => selectDashletData(state, dashletId));
    const backendKey = dashlet.config.backendKey;
    const stompClient = useSelector(selectStompClient);
    const isStompClientConnected = useSelector(selectIsStompClientConnected);
    const userSessionId = getUserSessionId();

    return {
        data,
        backendKey,
        stompClient,
        isStompClientConnected,
        userSessionId,
    }
}

export default useCommonDashletProperties;
