import AccordionPanelRestClient from "api/rest/AccordionPanelRestClient";
import { onAddAccordion, onAddAccordionList, unSetTargetConfiguration } from "reducers/dashboard/dashboardSlice";
import { removeAccordionPanel } from "reducers/dashboard/dashboardSlice";
import { createDashlet, createDashletBatch } from "./dashboard/dashletActions";
import { ANOMALY_DASHBOARD, DASHLET_TYPE_NAME, DEFAULT_DASHBOARD } from "dashlets/AnomalyDetectionDashlet/constants";
import { dashboardByIdSelector } from "selectors/dashboard/DashboardSelectors";

export const createPanel = (accordion, templateList, anomalyDashletList, backendKey, targetName) => async (dispatch, getState) => {
    AccordionPanelRestClient.create(accordion)
      .then(accordion => {
        const state = getState();
        const dashboard = dashboardByIdSelector(state, accordion.dashboardId);
        dispatch(onAddAccordion(accordion))
        if (dashboard.dashboardType === DEFAULT_DASHBOARD && templateList) {
          templateList.forEach(template => {
            let dashlet = {
              ...template,
              accordionId: accordion.id,
              dashboardId: accordion.dashboardId,
              type: "HLC_CHART_DASHLET_TYPE"
            }
            delete dashlet.backendKey;
            dispatch(createDashlet(dashlet));
          });
        } else if (dashboard.dashboardType === ANOMALY_DASHBOARD) {
          const anomalyDashlets = Object.keys(anomalyDashletList).map(capture => ({
              config: {
                  backendKey: backendKey,
                  targetName: targetName
              },
              accordionId: accordion.id,
              dashboardId: accordion.dashboardId,
              name: capture,
              type: DASHLET_TYPE_NAME
          }));
          dispatch(createDashletBatch(anomalyDashlets));
        }
        dispatch(unSetTargetConfiguration(state));
      }
      ).catch(error => console.error(error));
  };


  export const createPanelBatch = (accordions, templateList, anomalyDashletList, backendKey) => async (dispatch, getState) => {
    AccordionPanelRestClient.createBatch(accordions)
    .then(accordions => {
      const state = getState();

      if (accordions.length > 0) {
        dispatch(onAddAccordionList(accordions))
      }

        const anomalyDashlets = accordions.flatMap(accordion => {
          const dashboard = dashboardByIdSelector(state, accordion.dashboardId);

          if (dashboard?.dashboardType === ANOMALY_DASHBOARD) {
              const captures = anomalyDashletList?.[accordion.name].captureList || {};

              return Object.keys(captures).map(capture => ({
                  config: {
                      backendKey: backendKey,
                      targetName: accordion.name
                  },
                  accordionId: accordion.id,
                  dashboardId: accordion.dashboardId,
                  name: capture,
                  type: DASHLET_TYPE_NAME
              }));
          }
          return [];
      });
      if (anomalyDashlets.length > 0) {
          dispatch(createDashletBatch(anomalyDashlets));
      }
      dispatch(unSetTargetConfiguration(state));
    });
  }

  export const removePanel = accordionId => dispatch =>
    AccordionPanelRestClient.delete(accordionId)
        .then(accordion => {
            dispatch(removeAccordionPanel(accordion));
        })
        .catch(error => console.error(error));