import React from "react";
import {Box} from "@material-ui/core";
import BackendSelectionRow from "components/common/BackendSelectionRow";
import {HLC_WEB_SOCKET_URL, IS_HLC_WS_ENABLED, IS_PEI_WS_ENABLED, PEI_WEB_SOCKET_URL} from "config";
import StompClient from "websocket/StompClient";
import {STOMP_CLIENT} from "reducers/webSocketSlice";
import {useDispatch, useSelector} from "react-redux";
import {
    selectBackendCollectorsBackendKey,
    selectBackendCollectorsEntities,
    selectBackendCollectorsIds, setBackendCollectorsBackendKey
} from "reducers/infrastructure/backendCollectorsSlice";
import BackendCollectorsTable from "./BackendCollectorsTable";
import {
    handleCollectorOperation, handleCollectorStatus,
    handleListCollectors,
    listCollectors, startCollector, stopCollector
} from "actions/infrastructure/backendCollectorsAction";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import {
    BACKEND_COLLECTOR_OPERATION_SUBSCRIPTION, BACKEND_COLLECTOR_STATUS_SUBSCRIPTION,
    LIST_BACKEND_COLLECTORS_SUBSCRIPTION
} from "api/websocket/hlc/backendCollectorsWebSocketClient";
import WebSocketSubscription from "../../../websocket/WebSocketSubscription";
import BackendUserGroupWebSocketSubscription from "../../../websocket/BackendUserGroupWebSocketSubscription";

const BackendCollectorsContent: React.FC = ({}) => {
    const dispatch = useDispatch();
    const backendKey = useSelector(selectBackendCollectorsBackendKey);
    const collectorEntities = useSelector(selectBackendCollectorsEntities);
    const collectorIds = useSelector(selectBackendCollectorsIds);

    const handleChangeBackend = (backendKey: string) => dispatch(setBackendCollectorsBackendKey(backendKey));

    const handleStartCollector = (targetId: number) => backendKey && dispatch(startCollector(backendKey, targetId));

    const handleStopCollector = (targetId: number) => backendKey && dispatch(stopCollector(backendKey, targetId));

    const load = () => backendKey && dispatch(listCollectors(backendKey));

    React.useEffect(() => {
        load();
    }, [backendKey])

    return (
        <>
            <Box style={{
                display: 'block',
                height: "100%",
                width: "100%",
                overflowY: "scroll",
                overflowX: "hidden"
            }}>
                <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: "100%",
                }}
                >
                    <BackendSelectionRow backendKey={backendKey} onChangeBackend={handleChangeBackend}/>

                    <BackendCollectorsTable
                        rows={collectorIds.map((id: string) => collectorEntities[id])}
                        startAction={handleStartCollector}
                        stopAction={handleStopCollector}
                    />
                </Box>
            </Box>

            {/* todo: move to common place */}
            {/* {IS_PEI_WS_ENABLED ? [
                <StompClient
                    key="hlc-ws-stomp-client-1582840873831"
                    stompClientKey={STOMP_CLIENT.PEI}
                    sockJsEndpoint={PEI_WEB_SOCKET_URL}
                    debugMode
                />,
            ] : null} */}
            {IS_HLC_WS_ENABLED ? [
                <StompClient
                    key="pei-ws-stomp-client-1582840873831"
                    stompClientKey={STOMP_CLIENT.HLC}
                    sockJsEndpoint={HLC_WEB_SOCKET_URL}
                    debugMode
                />,
            ] : null}

            <HLCUserSessionWebSocketSubscription
                subscriptionDestination={LIST_BACKEND_COLLECTORS_SUBSCRIPTION}
                handleMessage={response => dispatch(handleListCollectors(response))}
                onSubscribeCallback={load}
            />
            <HLCUserSessionWebSocketSubscription
                subscriptionDestination={BACKEND_COLLECTOR_OPERATION_SUBSCRIPTION}
                handleMessage={response => dispatch(handleCollectorOperation(response))}
            />
            <BackendUserGroupWebSocketSubscription
                backendKey={backendKey}
                subscriptionDestination={BACKEND_COLLECTOR_STATUS_SUBSCRIPTION}
                handleMessage={response => dispatch(handleCollectorStatus(response))}
            />
        </>
    );
}

export default BackendCollectorsContent;
