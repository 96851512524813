import NotificationRestClient from "api/rest/NotificationRestClient";
import { showInfoNotification } from "components/toastr/notificationActions";
import { setNotifications, setNotificationsCount, setNotificationsLoading } from "reducers/notificationsSlice";

export const fetchNotifications = () => dispatch => {
    dispatch(setNotificationsLoading(true));
  NotificationRestClient.allNotificationStatus()
      .then(resultObject => {
        dispatch(setNotifications(resultObject.notifications));
        dispatch(totalNotifications());
        dispatch(setNotificationsLoading(false));
    })
    .catch(error => {
        dispatch(setNotificationsLoading(false));
    });
};

export const markAsReadNotification = (id) => dispatch => {
    NotificationRestClient.markNotificationAsRead(id)
        .then(resultObject =>  dispatch(setNotificationsCount(resultObject)));
};

export const totalNotifications = () => dispatch => {
    NotificationRestClient.notificationCount()
        .then(resultObject =>  {
            dispatch(setNotificationsCount(resultObject))});
};